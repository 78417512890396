import axios from 'axios'
import {CreateCustomerModel, CustomerModel, UpdateCustomerModel} from './Models'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_All_CUSTOMERS_URL = `${API_URL}/Customers/getall`
export const GET_All_REPORTS_URL = `${API_URL}/ZReports/getall`
export const CUSTOMER_URL = `${API_URL}/Customers`
export const DELETE_CUSTOMER_URL = `${API_URL}/Customers?deleteCustomerId=`

export function GetAllCustomers() {
  return axios.get<CustomerModel[]>(GET_All_CUSTOMERS_URL)
}
export function CreateCustomer(customer: CreateCustomerModel) {
  return axios.post(CUSTOMER_URL, customer)
}
export function UpdateCustomer(customer: UpdateCustomerModel) {
   
  return axios.put(CUSTOMER_URL, customer)
}
export function DeleteCustomer(customerId: number) {
  return axios.delete(DELETE_CUSTOMER_URL + customerId)
}