import {DataGrid, GridColDef} from '@mui/x-data-grid'
import React, {useEffect, useState} from 'react'
import {
  CreateGroup,
  DeleteGroup,
  GetAllGroups,
  GetAllMenuItems,
  UpdateGroup,
  UpdateGroupMenuItemRights,
} from './ApiOperations'
import clsx from 'clsx'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {KTSVG} from '../../../../_metronic/helpers'
import {useFormik} from 'formik'
import {
  CreateGroupModel,
  MenuItemModel,
  UpdateGroupMenuItemRightModel,
  UpdateGroupModel,
} from './Models'
import CheckboxTree, {Node} from 'react-checkbox-tree'
import {
  //createGroupSchema,
  initialCreateGroupValues,
  initialUpdateGroupValues,
  //updateGroupSchema,
} from './Form'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import {AgGridColumn, AgGridReact} from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import {GetIsPermissionGranted} from '../../general/GeneralApiOperations'
import { useIntl } from 'react-intl'
import { Languages } from '../../../../_metronic/partials/layout/header-menus/Languages'
const mySwal = withReactContent(Swal)

let menuItems: MenuItemModel[] = []

const GroupManagement = () => {
  const [isGranted, setisGranted] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [tableRows, setTableRows] = useState<any[]>([])
  const [currentGroup, setcurrentGroup] = useState<UpdateGroupModel>(initialUpdateGroupValues)
  const [currentGroupIsActive, setcurrentGroupIsActive] = useState(false)
  const [checkedAuths, setcheckedAuths] = useState<string[]>([])
  const [expandedAuths, setexpandedAuths] = useState<string[]>([])
  const [authTreeNodes, setauthTreeNodes] = useState<Node[]>([])
  
  //#region Grid Methods
  const onGridReady = (params: any) => {
    var gridApi = params.api
    gridApi.sizeColumnsToFit()
  }
  const EditCellRenderer = (params: any) => {
    const onClick = (e: any) => {
      e.stopPropagation() // don't select this row after clicking
      var group = params.data

      var xGroup: UpdateGroupModel = {
        id: group.id,
        groupName: group.groupName,
        description: group.description,
        isActive: group.isActive,
        menuItems: group.menuItems,
      }
      setcurrentGroupIsActive(group.isActive)
      setcurrentGroup(xGroup)
      console.log(currentGroup)
    }

    return (
      <button
        data-bs-toggle='modal'
        data-bs-target='#UpdateGroupModal'
        className='btn btn-sm btn-info w-100'
        onClick={onClick}
      >
        <span>{language["PAGES.MANAGEMENT.GROUP.EDITBUTTON"]}</span>

        
      </button>
    )
  }
  const AuthsCellRenderer = (params: any) => {
     
    const onClick = (e: any) => {
      e.stopPropagation() 
      var group = params.data

      var xGroup: UpdateGroupModel = {
        id: group.id,
        groupName: group.groupName,
        description: group.description,
        isActive: group.isActive,
        menuItems: group.menuItems,
      }
      setcurrentGroup(xGroup)
      setcheckedAuths(group.menuItems)
    }

    return (
      <button
        data-bs-toggle='modal'
        data-bs-target='#GroupAuthModal'
        className='btn btn-sm btn-success w-100'
        onClick={onClick}
      >
        <span>{language["PAGES.MANAGEMENT.GROUP.AUTHORITIESBUTTON"]}</span>
      </button>
    )
  }
  const DeleteCellRenderer = (params: any) => {
    const onClick = (e: any) => {
      e.stopPropagation()
      setLoading(true)
      var xgroup = params.data;
        mySwal
          .fire({
            title: language["PAGES.MANAGEMENT.GROUP.DELETECONFIRMTITLE"].toString(),
            icon: 'info',
            text: `${xgroup.groupName}  ${language["PAGES.MANAGEMENT.GROUP.DELETEMESSAGE"]}`,
            showDenyButton: true,
            confirmButtonText: language["PAGES.MANAGEMENT.GROUP.DELETEBUTTON"].toString(),
            denyButtonText: language["PAGES.MANAGEMENT.GROUP.CANCELBUTTON"].toString(),
          })
          .then((result) => {
            if (result.isConfirmed) {
              DeleteGroup(xgroup.id)
                .then((response) => {
                  mySwal
                    .fire(language["PAGES.MANAGEMENT.GROUP.DELETESUCCESS"].toString(), language["PAGES.MANAGEMENT.GROUP.DELETESUCCESSMESSAGE"].toString(), 'success')
                    .then(() => {
                      GetData()
                      setLoading(false)
                    })
                })
                .catch(() => {
                  mySwal.fire(language["PAGES.MANAGEMENT.GROUP.DELETEERROR"].toString(), language["PAGES.MANAGEMENT.GROUP.DELETEERRORMESSAGE"].toString(), 'error').then(() => {
                    setLoading(false)
                  })
                })
            }
            setLoading(false)
          })
      
    }

    return (
      <button className='btn btn-sm btn-danger w-100' onClick={onClick}>
        {!isLoading && <span className='indicator-label'>{language["PAGES.MANAGEMENT.GROUP.DELETEBUTTON"]}</span>}
        {isLoading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            {language["PAGES.MANAGEMENT.GROUP.PLEASEWAIT"]}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    )
  }
  const IsActiveCellRenderer = (params: any) => {
    var isActive = params.data.isActive;
    return (
      <div className='mb-0'>
        <div className='form-check form-check-custom form-check-solid'>
          {isActive ? (
            <input
              className='form-check-input mt-2'
              type='checkbox'
              disabled
              value=''
              checked
            />
          ) : (
            <input className='form-check-input mt-2' type='checkbox' disabled value='' />
          )}
        </div>
      </div>
    )
  }
  //#endregion

  const selectedLanguage = useIntl().locale

  //#region global Methods
  const GetAuthTreeViewList = (list: any[], isChild: boolean = false) => {
    if (list) {
      list = list.sort((a, b) => {
        if (a.isMain > b.isMain) {
          return -1
        }
        if (a.isMain < b.isMain) {
          return 1
        }
        return 0
      })
      let newList: Node[] = []
      for (var i = 0; i < list.length; i++) {
        var childrens = menuItems.filter((m) => m.parentMenuId == list[i].id)
        var parentCount = menuItems.filter((x) => x.id === list[i].parentMenuId).length
        if (
          (list[i].parentMenuId === 0 && childrens.length > 0) ||
          (list[i].parentMenuId !== 0 && childrens.length > 0 && parentCount === 0) ||
          (list[i].parentMenuId !== 0 && childrens.length > 0 && isChild)
        ) {
          var parentNode: Node = {
            label: selectedLanguage==='en' ? list[i].displayText_EN : list[i].displayText,
            value: list[i].id.toString(),
            children: [],
          }
          parentNode.children?.splice(0, 1)

          let returnedChildrens = GetAuthTreeViewList(childrens, true)
          returnedChildrens?.forEach((children) => {
            parentNode.children?.push(children)
          })
          newList.push(parentNode)
        } else if (list[i].parentMenuId === 0) {
          var parentNode: Node = {
            label: selectedLanguage==='en' ? list[i].displayText_EN : list[i].displayText,
            value: list[i].id.toString(),
          }
          newList.push(parentNode)
        } else if (isChild) {
          var childNode: Node = {
            label: selectedLanguage==='en' ? list[i].displayText_EN : list[i].displayText,
            value: list[i].id.toString(),
          }
          newList.push(childNode)
        }
      }
      return newList
    }
  }
  const UpdateGroupMenuItemRightsByGroup = (model: UpdateGroupMenuItemRightModel) => {
    UpdateGroupMenuItemRights(model)
      .then(() => {
        mySwal.fire(language["PAGES.MANAGEMENT.GROUP.UPDATESUCCESS"].toString(), language["PAGES.MANAGEMENT.GROUP.UPDATESUCCESSMESSAGE"].toString(), 'success').then(() => {
          GetData()
          setLoading(false)
          window.location.reload()
        })
      })
      .catch(() => {
        mySwal
          .fire(language["PAGES.MANAGEMENT.GROUP.UPDATEERROR"].toString(), language["PAGES.MANAGEMENT.GROUP.UPDATEERRORMESSAGE"].toString(), 'error')
          .then(() => {
            GetData()
            setLoading(false)
          })
      })
  }
  const GetMenuItems = () => {
    GetAllMenuItems()
      .then((response) => {
        menuItems = []
        var xmenuItems = response.data
        xmenuItems.forEach((xmenuItem) => {
          menuItems.push(xmenuItem)
        })
        var treeNodes = GetAuthTreeViewList(xmenuItems)
        setauthTreeNodes(treeNodes!)
      })
      .catch(() => {
        alert(language["PAGES.MANAGEMENT.GROUP.EXCEPTIONMESSAGE"].toString())
      })
  }
  const GetData = () => {
    GetAllGroups()
      .then((response) => {
        var groups = response.data
        if(selectedLanguage === 'en'){
          groups.forEach((group)=>{
            group.description= group.id === 1 ? language["PAGES.MANAGEMENT.GROUP.STANDARTUSERDESCRIPTION"].toString() : group.id === 2 ? language["PAGES.MANAGEMENT.GROUP.ADMINUSERDESCRIPTION"].toString() : language["PAGES.MANAGEMENT.GROUP.SUPERADMINUSERDESCRIPTION"].toString()
          })
        }
        setTableRows(groups)
        GetMenuItems()
      })
      .catch((error) => {
        alert(language["PAGES.MANAGEMENT.GROUP.EXCEPTIONMESSAGE"].toString() + error.response)
      })
  }
  //#endregion

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      GetIsPermissionGranted('/group-management').then((response) => {
        var isGranted = response.data

        setisGranted(isGranted)

         
        if (isGranted === false) {
          window.location.href = '/error/404'
        }
      })
      GetData()
    }
    return () => {
      isMounted = false
    }
  }, [])

  const language = useIntl().messages

  const createGroupSchema = Yup.object().shape({
    groupName: Yup.string()
      .typeError(language["PAGES.MANAGEMENT.GROUP.GROUPNAMEVALIDATONMESSAGE"].toString())
      .min(3, language["PAGES.MANAGEMENT.GROUP.GROUPNAMECHARACTERVALIDATION"].toString())
      .required(language["PAGES.MANAGEMENT.GROUP.GROUPNAMEVALIDATONMESSAGE"].toString()),
    description: Yup.string()
      .typeError(language["PAGES.MANAGEMENT.GROUP.DESCRIPTIONVALIDATONMESSAGE"].toString())
      .min(3, language["PAGES.MANAGEMENT.GROUP.DESCRIPTIONCHARACTERVALIDATION"].toString())
      .required(language["PAGES.MANAGEMENT.GROUP.DESCRIPTIONVALIDATONMESSAGE"].toString()),
  })
  const updateGroupSchema = Yup.object().shape({
    groupName: Yup.string()
      .typeError(language["PAGES.MANAGEMENT.GROUP.GROUPNAMEVALIDATONMESSAGE"].toString())
      .min(3, language["PAGES.MANAGEMENT.GROUP.GROUPNAMECHARACTERVALIDATION"].toString())
      .required(language["PAGES.MANAGEMENT.GROUP.GROUPNAMEVALIDATONMESSAGE"].toString()),
    description: Yup.string()
      .typeError(language["PAGES.MANAGEMENT.GROUP.DESCRIPTIONVALIDATONMESSAGE"].toString())
      .min(3, language["PAGES.MANAGEMENT.GROUP.DESCRIPTIONCHARACTERVALIDATION"].toString())
      .required(language["PAGES.MANAGEMENT.GROUP.DESCRIPTIONVALIDATONMESSAGE"].toString()),
    isActive: Yup.boolean(),
  })

  const CreateGroupFormik = useFormik({
    initialValues: initialCreateGroupValues,
    validationSchema: createGroupSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setTimeout(() => {
        CreateGroup(values as CreateGroupModel)
          .then((reponse) => {
            mySwal.fire(language["PAGES.MANAGEMENT.GROUP.CREATESUCCESS"].toString(), language["PAGES.MANAGEMENT.GROUP.CREATESUCCESSMESSAGE"].toString(), 'success').then(() => {
              GetData()
              resetForm()
              setLoading(false)
              window.location.reload()
            })
          })
          .catch((error) => {
            setLoading(false)
            setSubmitting(false)
            mySwal.fire(
              language["PAGES.MANAGEMENT.GROUP.CREATEERROR"].toString(),
              language["PAGES.MANAGEMENT.GROUP.CREATEERRORMESSAGE"].toString(),
              'error'
            )
            setStatus(error.message)
          })
      }, 1000)
    },
  })

  const UpdateGroupFormik = useFormik({
    enableReinitialize: true,
    initialValues: currentGroup,
    validationSchema: updateGroupSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      values.isActive = currentGroupIsActive
      setLoading(true)
      setTimeout(() => {
        UpdateGroup(values as UpdateGroupModel)
          .then((reponse) => {
            mySwal.fire(language["PAGES.MANAGEMENT.GROUP.UPDATESUCCESS"].toString(), language["PAGES.MANAGEMENR.GROUP.UPDATESUCCESSMESSAGESECOND"].toString(), 'success').then(() => {
              GetData()
              resetForm()
              setLoading(false)
              window.location.reload()
            })
          })
          .catch((error) => {
            setLoading(false)
            setSubmitting(false)
            mySwal.fire(
              language["PAGES.MANAGEMENT.GROUP.UPDATEERROR"].toString(),
              language["PAGES.MANAGEMENT.GROUP.UPDATEERRORMESSAGESECOND"].toString(),
              'error'
            )
            setStatus(error.message)
          })
      }, 1000)
    },
  })


  return (
    <>
      {isGranted ? (
        <>
          <>
            <div className='row alert alert-primary d-flex align-items-center pb-10 text-center'>
              <div className='col-md-10'>
                <div className='d-flex flex-column'>
                  <h5 className='mb-1'> {language["PAGES.MANAGEMENT.GROUP.PAGETITLE"]}</h5>
                  <span>
                    {language["PAGES.MANAGEMENT.GROUP.PAGEDESCRIPTION"]}
                  </span>
                </div>
              </div>

              <div className='col-md-2 '>
                <button
                  data-bs-toggle='modal'
                  data-bs-target='#NewGroupModal'
                  className='btn btn-md btn-primary float-right mt-5 w-100 '
                >
                  <i className='fa fa-plus' aria-hidden='true'></i>
                  {language["PAGES.MANAGEMENT.GROUP.NEWGROUPBUTTON"]}
                </button>
              </div>
            </div>

            <div className='card card-custom  card-stretch shadow '>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='ag-theme-alpine' style={{height: 600, width: '100%'}}>
                      <AgGridReact
                        frameworkComponents={{
                          authCellRenderer: AuthsCellRenderer,
                          isActiveCellRenderer:IsActiveCellRenderer,
                          editCellRenderer:EditCellRenderer,
                          deleteCellRenderer:DeleteCellRenderer
                        }}
                        defaultColDef={{
                          
                          sortable: true,
                          flex: 1,
                          filter: true,
                          resizable: true,
                        }}
                        onGridReady={onGridReady}
                        rowData={tableRows}
                      >
                        <AgGridColumn field='id' hide={true} />
                        <AgGridColumn field='menuItems' hide={true} />
                        <AgGridColumn field='groupName' headerName={language["PAGES.MANAGEMENT.GROUP.GRID.GROUPNAME"].toString()}  />
                        <AgGridColumn field='description' headerName={language["PAGES.MANAGEMENT.GROUP.GRID.DESCRIPTION"].toString()}  />
                        <AgGridColumn field='createdByStr' headerName={language["PAGES.MANAGEMENT.GROUP.GRID.CREATEDBY"].toString()}   />
                        <AgGridColumn field='createdDateStr' headerName={language["PAGES.MANAGEMENT.GROUP.GRID.CREATEDDATE"].toString()}  />
                        <AgGridColumn field='isActive' headerName={language["PAGES.MANAGEMENT.GROUP.GRID.ISACTIVE"].toString()} width={100} cellRenderer='isActiveCellRenderer' />
                        <AgGridColumn field='yetkiler' pinned="right"  width={125} headerName={language["PAGES.MANAGEMENT.GROUP.GRID.AUTHORITIES"].toString()} cellRenderer='authCellRenderer' />
                        <AgGridColumn field='düzenle' pinned="right" width={125} headerName={language["PAGES.MANAGEMENT.GROUP.GRID.EDIT"].toString()} cellRenderer='editCellRenderer' />
                        <AgGridColumn field='sil' pinned="right" width={100} headerName={language["PAGES.MANAGEMENT.GROUP.GRID.DELETE"].toString()} cellRenderer='deleteCellRenderer' />
                      </AgGridReact>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='modal fade' tabIndex={-1} id='NewGroupModal'>
              <div className='modal-dialog modal-xl'>
                <div className='modal-content'>
                  <form className='form w-100' onSubmit={CreateGroupFormik.handleSubmit} noValidate>
                    <div className='modal-header'>
                      <h5 className='modal-title'>{language["PAGES.MANAGEMENT.GROUP.NEWGROUPTITLE"]}</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </div>
                    <div className='modal-body text-center'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.GROUP.EDITUPDATEGROUPNAME"]}</label>
                        <input
                          placeholder={language["PAGES.MANAGEMENT.GROUP.EDITUPDATEGROUPNAMEPLACEHOLDER"].toString()}
                          {...CreateGroupFormik.getFieldProps('groupName')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid text-center',
                            {
                              'is-invalid':
                                CreateGroupFormik.touched.groupName &&
                                CreateGroupFormik.errors.groupName,
                            },
                            {
                              'is-valid':
                                CreateGroupFormik.touched.groupName &&
                                !CreateGroupFormik.errors.groupName,
                            }
                          )}
                          type='text'
                          name='groupName'
                          autoComplete='off'
                        />
                        {CreateGroupFormik.touched.groupName && CreateGroupFormik.errors.groupName && (
                          <div className='fv-plugins-message-container'>
                            <span role='alert' className='text-danger'>
                              {CreateGroupFormik.errors.groupName}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.GROUP.EDITUPDATEDESCRIPTION"]}</label>
                        <textarea
                          placeholder={language["PAGES.MANAGEMENT.GROUP.EDITUPDATEDESCRIPTIONPLACEHOLDER"].toString()}
                          {...CreateGroupFormik.getFieldProps('description')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid text-center',
                            {
                              'is-invalid':
                                CreateGroupFormik.touched.description &&
                                CreateGroupFormik.errors.description,
                            },
                            {
                              'is-valid':
                                CreateGroupFormik.touched.description &&
                                !CreateGroupFormik.errors.description,
                            }
                          )}
                          name='description'
                          autoComplete='off'
                        />
                        {CreateGroupFormik.touched.description &&
                          CreateGroupFormik.errors.description && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {CreateGroupFormik.errors.description}
                              </span>
                            </div>
                          )}
                      </div>

                      {/* end::Form group */}
                    </div>
                    <div className='modal-footer'>
                      <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                        Kapat
                      </button>
                      <button type='submit' className='btn btn-primary'>
                        {!isLoading && <span>{language["PAGES.MANAGEMENT.GROUP.CREATEBUTTON"]}</span>}
                        {isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            {language["PAGES.MANAGEMENT.GROUP.PLEASEWAIT"]}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='modal fade' tabIndex={-1} id='UpdateGroupModal'>
              <div className='modal-dialog modal-xl'>
                <div className='modal-content'>
                  <form className='form w-100' onSubmit={UpdateGroupFormik.handleSubmit}>
                    <div className='modal-header'>
                      <h5 className='modal-title'>{language["PAGES.MANAGEMENT.GROUP.EDITUPDATETITLE"]}</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </div>
                    <div className='modal-body text-center'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.GROUP.EDITUPDATEGROUPNAME"]}</label>
                        <input
                          placeholder={language["PAGES.MANAGEMENT.GROUP.EDITUPDATEGROUPNAMEPLACEHOLDER"].toString()}
                          {...UpdateGroupFormik.getFieldProps('groupName')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid text-center',
                            {
                              'is-invalid':
                                UpdateGroupFormik.touched.groupName &&
                                UpdateGroupFormik.errors.groupName,
                            },
                            {
                              'is-valid':
                                UpdateGroupFormik.touched.groupName &&
                                !UpdateGroupFormik.errors.groupName,
                            }
                          )}
                          type='text'
                          name='groupName'
                          autoComplete='off'
                        />
                        {UpdateGroupFormik.touched.groupName && UpdateGroupFormik.errors.groupName && (
                          <div className='fv-plugins-message-container'>
                            <span role='alert' className='text-danger'>
                              {UpdateGroupFormik.errors.groupName}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.GROUP.EDITUPDATEDESCRIPTION"]}</label>
                        <textarea
                          placeholder={language["PAGES.MANAGEMENT.GROUP.EDITUPDATEDESCRIPTIONPLACEHOLDER"].toString()}
                          {...UpdateGroupFormik.getFieldProps('description')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid text-center',
                            {
                              'is-invalid':
                                UpdateGroupFormik.touched.description &&
                                UpdateGroupFormik.errors.description,
                            },
                            {
                              'is-valid':
                                UpdateGroupFormik.touched.description &&
                                !UpdateGroupFormik.errors.description,
                            }
                          )}
                          name='description'
                          autoComplete='off'
                        />
                        {UpdateGroupFormik.touched.description &&
                          UpdateGroupFormik.errors.description && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {UpdateGroupFormik.errors.description}
                              </span>
                            </div>
                          )}
                      </div>
                      <div className='row mb-10 '>
                        <div className='col-sm-2  p-0'>
                          <p className='fs-6 fw-bolder ml-5'>{language["PAGES.MANAGEMENT.GROUP.EDITUPDATEISACTIVE"]}</p>
                        </div>
                        <div className='col-sm-1 p-0'>
                          <div className='form-check form-check-custom form-check-solid '>
                            {
                              <input
                                {...UpdateGroupFormik.getFieldProps('isActive')}
                                className={clsx(
                                  'form-check-input ',
                                  {
                                    'is-invalid':
                                      UpdateGroupFormik.touched.isActive &&
                                      UpdateGroupFormik.errors.isActive,
                                  },
                                  {
                                    'is-valid':
                                      UpdateGroupFormik.touched.isActive &&
                                      !UpdateGroupFormik.errors.isActive,
                                  }
                                )}
                                type='checkbox'
                                name='isActive'
                                checked={currentGroupIsActive}
                                onChange={(e) => {
                                  setcurrentGroupIsActive(e.target.checked)
                                }}
                              />
                            }
                          </div>
                        </div>
                      </div>
                      {/* end::Form group */}
                    </div>
                    <div className='modal-footer'>
                      <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                      {language["PAGES.MANAGEMENT.GROUP.CANCELBUTTON"]}
                      </button>
                      <button type='submit' className='btn btn-primary'>
                        {!isLoading && <span>{language["PAGES.MANAGEMENT.GROUP.UPDATEBUTTON"]}</span>}
                        {isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            {language["PAGES.MANAGEMENT.GROUP.PLEASEWAIT"]}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='modal fade' tabIndex={-1} id='GroupAuthModal'>
              <div className='modal-dialog '>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title'>{language["PAGES.MANAGEMENT.GROUP.AUTHORITYUPDATETITLE"]}</h5>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body text-center mb-10 mt-5'>
                    {/* begin::Form group */}
                    <div className='row text-center'>
                      <div className='col-md-12 mb-10'>
                        <p className='fs-3'>
                          <strong> "{currentGroup.groupName}"</strong> {language["PAGES.MANAGEMENT.GROUP.AUTHORITYUPDATEMESSAGE"]}
                        </p>
                        <br />
                        <div className='border border-secondary h-100'>
                          <CheckboxTree
                            nodes={authTreeNodes}
                            checked={checkedAuths}
                            expanded={expandedAuths}
                            checkModel='all'
                            onCheck={(checked) => {
                              console.log(checked)

                              setcheckedAuths(checked)
                            }}
                            onExpand={(expanded) => setexpandedAuths(expanded)}
                            iconsClass='fa5'
                            noCascade={true}
                          />
                        </div>
                      </div>
                    </div>

                    {/* end::Form group */}
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                      {language["PAGES.MANAGEMENT.GROUP.CANCELBUTTON"]}
                    </button>
                    <button
                      type='button'
                      onClick={() => {
                        var model: UpdateGroupMenuItemRightModel = {
                          groupId: currentGroup.id,
                          menuItemIds: checkedAuths,
                        }
                        UpdateGroupMenuItemRightsByGroup(model)
                      }}
                      className='btn btn-primary'
                    >
                      {!isLoading && <span>{language["PAGES.MANAGEMENT.GROUP.UPDATEBUTTON"]}</span>}
                      {isLoading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          {language["PAGES.MANAGEMENT.GROUP.PLEASEWAIT"]}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default GroupManagement
