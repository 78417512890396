import axios from 'axios'
import {AddReportToCustomerModel, CustomerZReportModel, ReportModel, UpdateReportModel} from './Models'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const CUSTOMER_ZREPORTS_URL = `${API_URL}/CustomerZReports`
export const GET_All_REPORTS_URL = `${API_URL}/ZReports/getall`
export const GET_All_CUSTOMER_ZREPORTS_URL = `${API_URL}/CustomerZReports/GetByCustomerId`

export function GetReports() {
  return axios.get<ReportModel[]>(GET_All_REPORTS_URL)
}
export function CreateReport(report: AddReportToCustomerModel) {
  return axios.post(CUSTOMER_ZREPORTS_URL, report)
}
export function UpdateReport(report: UpdateReportModel) {
  return axios.put(CUSTOMER_ZREPORTS_URL, report)
}
export function GetCustomersZReports(customerId:string) {
    return axios.post<CustomerZReportModel[]>(GET_All_CUSTOMER_ZREPORTS_URL,{customerId:customerId})
  }