import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {ResetUserPassword} from '../redux/AuthCRUD'

import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
const mySwal = withReactContent(Swal)

const initialValues = {
  password: '',
  passwordAgain: '',
}


const ResetPassword = () => {
  const {passwordResetKey} = useParams<{passwordResetKey: string}>()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [isCorrect, setIsCorrect] = useState(false)

  const language = useIntl().messages


  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string().min(3, language["PAGES.RESETPASSWORD.PASSWORDVALIDATIONCHARACTERMESSAGE"].toString()).required(language["PAGES.RESETPASSWORD.PASSWORDVALIDATIONMESSAGE"].toString()),
    passwordAgain: Yup.string()
      .min(3, language["PAGES.RESETPASSWORD.PASSWORDVALIDATIONCHARACTERMESSAGE"].toString())
      .required(language["PAGES.RESETPASSWORD.PASSWORDVALIDATIONMESSAGE"].toString()),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      if (values.password.trim() === values.passwordAgain.trim()) {
        var password = values.password
        var passwordAgain = values.passwordAgain
        setTimeout(() => {
          ResetUserPassword(password, passwordAgain, false, passwordResetKey)
            .then((response) => {
              setLoading(false)
              mySwal
                .fire(
                  language["PAGES.RESETPASSWORD.SUCCESS"].toString(),
                  language["PAGES.RESETPASSWORD.SUCCESSMESSAGE"].toString(),
                  'success'
                )
                .then(() => {
                  window.location.href = '/auth/login'
                })
            })
            .catch(() => {
              setHasErrors(true)
              setLoading(false)
              setSubmitting(false)
              setStatus('')
            })
        }, 1000)
      } else {
        mySwal.fire(
          language["PAGES.RESETPASSWORD.INFORMATION"].toString(),
          language["PAGES.RESETPASSWORD.INFORMATIONMESSAGESECOND"].toString(),
          'info'
        )
      }
    },
  })
  useEffect(() => {
    setTimeout(() => {
      ResetUserPassword('', '', true, passwordResetKey)
        .then((response) => {
          setIsCorrect(true)
        })
        .catch(() => {
          setIsCorrect(false)
          mySwal
            .fire(
              language["PAGES.RESETPASSWORD.ERROR"].toString(),
              language["PAGES.RESETPASSWORD.RESETPASSWORDURLERROR"].toString(),
              'error'
            )
            .then(() => {
              window.location.href = '/auth/login'
            })
        })
    }, 1000)
  }, [passwordResetKey])



  return (
    <>
      {isCorrect ? (
        <div
          className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/background/bg-image.png')})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100vw',
            height: '100vh',
          }}
        >
          {/* begin::Content */}
          <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            {/* begin::Logo */}
            {/* <a href='#' className='mb-12'>
    <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-45px' />
  </a> */}
            {/* end::Logo */}
            {/* begin::Wrapper */}
            <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_password_reset_form'
                onSubmit={formik.handleSubmit}
              >
                <div className='text-center mb-10'>
                  {/* begin::Title */}
                  <h1 className='text-dark mb-3'>{language["PAGES.RESETPASSWORD.NEWPASSWORDTITLE"]} </h1>
                  {/* end::Title */}

                  {/* begin::Link */}
                  <div className='text-gray-400 fw-bold fs-4'>
                    {language["PAGES.RESETPASSWORD.INFORMATIONMESSAGE"]}
                  </div>
                  {/* end::Link */}
                </div>

                {/* begin::Title */}
                {hasErrors === true && (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                      {language["PAGES.RESETPASSWORD.ERRORMESSAGE"]}
                    </div>
                  </div>
                )}

                {/* end::Title */}

                {/* begin::Form group */}
                <div className='fv-row mb-10'>
                  <label className='form-label fw-bolder text-gray-900 fs-6'>{language["PAGES.RESETPASSWORD.NEWPASSWORD"]}</label>
                  <input
                    placeholder={language["PAGES.RESETPASSWORD.PASSWORDPLACEHOLDER"].toString()}
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.password && formik.errors.password},
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.password}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='fv-row mb-10'>
                  <label className='form-label fw-bolder text-gray-900 fs-6'>{language["PAGES.RESETPASSWORD.NEWPASSWORDAGAIN"]}</label>
                  <input
                    placeholder={language["PAGES.RESETPASSWORD.PASSWORAGAINDPLACEHOLDER"].toString()}
                    autoComplete='off'
                    {...formik.getFieldProps('passwordAgain')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.passwordAgain && formik.errors.passwordAgain},
                      {
                        'is-valid': formik.touched.passwordAgain && !formik.errors.passwordAgain,
                      }
                    )}
                  />
                  {formik.touched.passwordAgain && formik.errors.passwordAgain && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-danger' role='alert'>
                          {formik.errors.passwordAgain}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                  <button
                    type='submit'
                    id='kt_password_reset_submit'
                    className='btn btn-lg btn-primary fw-bolder me-4'
                  >
                    {!loading && <span>{language["PAGES.RESETPASSWORD.UPDATEBUTTON"]}</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {language["PAGES.RESETPASSWORD.PLEASEWAIT"]}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* end::Form group */}
              </form>
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Content */}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default ResetPassword
