import {FC, useEffect, useState, forwardRef, useImperativeHandle} from 'react'
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd'
import ReactECharts from 'echarts-for-react'
import {Datum, Legend, OptionModel, Title} from '../models/OptionModel'
import {SelectItem} from '../../../../api-models/SelectItem'
import {
  DataCubeData,
  GetAllCollectionNames,
  GetReportBuilderDataByCollectionName,
} from '../../../../wizards/components/api/GraphicReportBuilderApiOperations'
import Select, {SingleValue} from 'react-select'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const mySwal = withReactContent(Swal)

const initialChartData = {
  dataCubeName: '[Veri Kümesi İsmi]',
  dataCubeColumns: [{label: 'Test Column', value: 'Test Column'}],
  dataCubeData: '',
}
const PieChartBuilder: FC = () => {
  const [chartColumns, setChartColumns] = useState<SelectItem[]>([])
  const [dataCubeColumns, setDataCubeColumns] = useState<SelectItem[]>([])
  const [chartData, setChartData] = useState<Datum[]>([])
  const [chartLegend, setChartLegend] = useState<Legend>({
    orient: 'vertical',
    left: 'left',
    top: 'center',
  })
  const [chartTitle, setChartTitle] = useState<Title>({
    text: 'Rapor başlığı',
    subtext: '',
    left: 'center',
  })
  const [dataCubeName, setDataCubeName] = useState<string>('')
  const [currentReportBuilderData, setCurrentReportBuilderData] =
    useState<DataCubeData>(initialChartData)
  const [reportName, setReportName] = useState<string>('')
  const [dataCubes, setDataCubes] = useState<SelectItem[]>([])
  const [selectedDataCube, setSelectedDataCube] = useState<string>('')
  const handleOnChangeDataCube = (value: any) => {
     
    setSelectedDataCube(value)
    setDataCubeName(value)
  }

  const handleOnChangeReportName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReportName(e.target.value)
    setChartTitle({
      text: e.target.value,
      subtext: '',
      left: 'center',
    })
  }

  const getFields = (input: any, field: string) => {
    var output = []
    for (var i = 0; i < input.length; ++i) output.push(input[i][field])
    return output
  }
  function onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index
  }

  const GetBuilderData = () => {
     
    GetReportBuilderDataByCollectionName(selectedDataCube)
      .then((response) => {
        setCurrentReportBuilderData(response.data)
        setDataCubeColumns(response.data.dataCubeColumns)
      })
      .catch((error) => {
        alert(error.response.message)
      })
  }
  useEffect(() => {
     
    let isMounted = true
    GetAllCollectionNames()
      .then((response) => {
        if (isMounted) {
          var collections = response.data.map((collectionName) => {
            return {label: collectionName, value: collectionName}
          })
          collections = collections.filter((collection)=>{
            return (collection.label.toLocaleLowerCase()!=='main')
          })
          setDataCubes(collections)
        }
      })
      .catch((error) => {
        console.log(error.response.message)
      })
    if (isMounted) {
      if (selectedDataCube !== '') {
        var convertedData = JSON.parse(currentReportBuilderData.dataCubeData.toLocaleLowerCase())
        setChartData([])
        var exactChartData: Datum[] = []
         

        if (chartColumns.length === 1) {
          var valueArray = getFields(
            convertedData,
            chartColumns[0].label.toLocaleLowerCase().trim()
          ).filter((x) => x != null)
          
          var uniqueColumns = valueArray.filter(onlyUnique)
          uniqueColumns.map((item) => {
            var repeatCount = valueArray.filter((v) => v === item).length
            exactChartData.push({name: item, value: repeatCount})
          })
        }

        setChartData(exactChartData)
        if (chartColumns.length === 0) {
          setChartData([])
        }
      }
    }
    return () => {
      isMounted = false
    }
  }, [JSON.stringify(chartColumns)])

  const onDragEnd = (result: DropResult) => {
    const {source, destination} = result
    console.log(result)
    if (!destination) return
    if (destination.droppableId === source.droppableId && destination.index === source.index) return
     
    if (
      chartColumns.length == 1 &&
      source.droppableId === 'ColumnSource' &&
      destination.droppableId === 'ColumnDestination'
    ) {
      mySwal.fire('Bilgi !', '<p>Pasta grafikte yalnızca bir alan kullanabilirsiniz.</p>', 'info')
    } else {
      let add,
        activeColumns = dataCubeColumns,
        selectedColumns = chartColumns.map((p) => p)

      if (source.droppableId === 'ColumnSource') {
        add = activeColumns[source.index]

        activeColumns.splice(source.index, 1)
      } else {
        add = selectedColumns[source.index]
        selectedColumns.splice(source.index, 1)
      }

      if (destination.droppableId === 'ColumnSource') {
        activeColumns.splice(destination.index, 0, add)
      } else {
        selectedColumns.splice(destination.index, 0, add)
      }

      setDataCubeColumns(activeColumns)
      setChartColumns(selectedColumns)
    }
  }
  let option: OptionModel = {
    title: chartTitle,
    tooltip: {trigger: 'item'},
    legend: chartLegend,
    toolbox: {
      show: true,
      feature: {
        dataView: {
          show: true,
          readOnly: true,
        },
        saveAsImage: {
          show: true,
        },
      },
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: '50%',
        data: chartData,
      },
    ],
  }
  return (
    <div className='row'>
      <div className='row mb-5'>
        <div className='col-md-12 text-center'>
          <div className='card card-custom  card-stretch shadow mb-5 w-100  h-100'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-12'>
                  <h5>Rapor İsmi :</h5>
                  <input
                    type='text'
                    name='reportName'
                    required={true}
                    placeholder='Bir rapor ismi giriniz...'
                    className='form-control text-center'
                    onChange={(e) => handleOnChangeReportName(e)}
                  />
                  {!reportName && <p className='text-danger'>Pano ismi zorunludur</p>}
                </div>
              </div>
              <div className='row '>
                <h5>Veri Kümesi Seçimi :</h5>
                <div className='col-md-8 mt-5 mb-5'>
                  <Select
                    name='dataCubeName'
                    onChange={(e) => handleOnChangeDataCube(e?.value)}
                    placeholder='Lütfen bir veri kümesi seçiniz..'
                    options={dataCubes}
                  />
                </div>
                <div className='col-md-4 mt-5 mb-5'>
                  <button className='btn btn-info w-75' onClick={() => GetBuilderData()}>
                    Getir
                  </button>
                </div>
                <input hidden name='dataCubeName' value={dataCubeName} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className='row '>
          <div className='col-md-3'>
            <div className='card card-custom  card-stretch shadow mb-5 w-100  h-100'>
              <div className='card-header '>
                <h3 className='card-title'>{currentReportBuilderData.dataCubeName}</h3>
              </div>

              <Droppable droppableId='ColumnSource'>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className='card-body list-group text-center '
                  >
                    {dataCubeColumns.length > 0 ? (
                      dataCubeColumns.map((column, index) => {
                        return (
                          <Draggable draggableId={column.value} index={index}>
                            {(prrovided) => (
                              <div
                                key={index}
                                className='list-group-item text-center'
                                {...prrovided.draggableProps}
                                {...prrovided.dragHandleProps}
                                ref={prrovided.innerRef}
                              >
                                {column.value}
                              </div>
                            )}
                          </Draggable>
                        )
                      })
                    ) : (
                      <p className='text-muted '>
                        Bir veri kümesi getirdiğinizde kolonları burada görünecek
                      </p>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <br />
              <div className='card-footer text-muted text-center'>
                Veri sütunlarını sağdaki alana sürükleyip bırakarak grafiği oluşturabilirsiniz.
              </div>
            </div>
          </div>
          <div className='col-md-9'>
            <div className='card card-custom  card-stretch shadow mb-5 w-100  h-100'>
              <div className='card-body'>
                <div className='row '>
                  <div className='col-sm border border-primary '>
                    <div className='card'>
                      <Droppable droppableId='ColumnDestination'>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className='card-body list-group '
                          >
                            {snapshot.isDraggingOver ? (
                              <span className='text-muted'>Buraya bırakın</span>
                            ) : (
                              <span className='text-muted'>
                                Kolonları buraya sürükleyip bırakabilirsiniz
                              </span>
                            )}
                            {chartColumns.map((column, index) => {
                              return (
                                <Draggable draggableId={column.value} index={index}>
                                  {(prrovided) => (
                                    <div
                                      key={index}
                                      className='list-group-item text-center'
                                      {...prrovided.draggableProps}
                                      {...prrovided.dragHandleProps}
                                      ref={prrovided.innerRef}
                                    >
                                      {column.value}
                                    </div>
                                  )}
                                </Draggable>
                              )
                            })}

                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </div>
                </div>
                <div className='separator separator-dotted border-dark my-10'></div>
                <div className='row '>
                  <div className='col-lg-12 text-center'>
                    <div className='row w-100'>
                      <div className='col-md-2'></div>
                      <div className='col-md-8  align-items-center'>
                        <div className='card'>
                          <div className='card-body '>
                            <ReactECharts option={option} theme={''} />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-2'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=' text-muted text-center mt-10 mb-10'>
          Veri sütunlarını sürükleyip bırakarak grafiği oluşturabilirsiniz.
        </div>
        <input hidden name='graphicOption' id='graphicOption' value={JSON.stringify(option)} />
        <input hidden name='graphicType' value='1' />
      </DragDropContext>
    </div>
  )
}

export {PieChartBuilder}
