import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {DbConnModel, QueryBuilderModel} from '../models/QueryBuilderModel'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SaveQuery: '[SaveQuery] API',
  FillDbConns: '[FillDbConns] Action',
}

const initialQueryState: QueryBuilderModel = {
  query: {
    targetDataTableName: '',
    sqlText: '',
    isLiveQuery: false,
    dbConnId: 0,
    commandType:0,
    startDate:"",
    endDate:"",
    customerNumbers:""
    
  },
  dbConnDefList :[]
}
export const reducer = persistReducer(
  {storage, key: 'query'},
  (state: QueryBuilderModel = initialQueryState, action: ActionWithPayload<QueryBuilderModel>) => {
    switch (action.type) {
      case actionTypes.SaveQuery: {
        const savedQuery: QueryBuilderModel = action.payload!
        return savedQuery;
      }
      case actionTypes.FillDbConns: {
        
        const dbConnDeflist = action.payload!.dbConnDefList
        return {...state, dbConnDefList :dbConnDeflist }
      }
      default:
        return state
    }
  }
)

export const actions = {
  saveQuery: () => ({type: actionTypes.SaveQuery, payload: {}}),
  fillDbConns: (dbConndDefs:DbConnModel[]) => ({type: actionTypes.FillDbConns, payload: {dbConnDefList:dbConndDefs}}),
}
