import axios from 'axios'
import {CreateReportModel, ReportModel, ReportType, UpdateReportModel} from './Models'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const ZREPORT_URL = `${API_URL}/ZReports`
export const GET_All_ZREPORTS_URL = `${API_URL}/ZReports/getall`
export const GET_REPORT_TYPES_URL = `${API_URL}/ReportTypes/getall`
export const DELETE_REPORT_URL = `${API_URL}/ZReports?deleteReportId=`

export function GetAllReports() {
  return axios.get<ReportModel[]>(GET_All_ZREPORTS_URL)
}
export function GetReportTypes() {
  return axios.get<ReportType[]>(GET_REPORT_TYPES_URL)
}
export function CreateReport(report: CreateReportModel) {
  return axios.post(ZREPORT_URL, report)
}
export function UpdateReport(report: UpdateReportModel) {
    return axios.put(ZREPORT_URL, report)
  }
export function DeleteReport(reportId: number) {
    return axios.delete(DELETE_REPORT_URL + reportId)
  }