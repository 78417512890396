import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {useFormik} from 'formik'
import {
  //createReportSchema,
  initialCreateReportValues,
  initialUpdateReportValues,
  //updateReportSchema,
} from './Form'
import {CreateReportModel, UpdateReportModel} from './Models'
import {
  CreateReport,
  DeleteReport,
  GetAllReports,
  GetReportTypes,
  UpdateReport,
} from './ApiOperations'
import {KTSVG} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import {DataGrid, GridColDef} from '@mui/x-data-grid'
import Select from 'react-select'
import {GetIsPermissionGranted} from '../../general/GeneralApiOperations'
import {AgGridColumn, AgGridReact} from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { useIntl } from 'react-intl'
const mySwal = withReactContent(Swal)


const ReportManagement = () => {
  const [isGranted, setisGranted] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [tableRows, setTableRows] = useState<any[]>([])
  const [currentReport, setCurrentReport] = useState<UpdateReportModel>(initialUpdateReportValues)
  const [currentReportIsActive, setCurrentReportIsActive] = useState(false)
  const [reportTypeOptions, setreportTypeOptions] = useState<any[]>([])
  const [selectedReportType, setSelectedReportType] = useState({label: '', value: 0})
  
  //#region Grid Methods
  const onGridReady = (params: any) => {
    var gridApi = params.api
    gridApi.sizeColumnsToFit()
  }
  const EditCellRenderer = (params: any) => {
    const onClick = (e: any) => {
      e.stopPropagation() 

      var report = params.data

      var xReport: UpdateReportModel = {
        id: report.id,
        reportName: report.reportName,
        isActive: report.isActive,
        reportTypeId: report.reportTypeId,
      }
      setCurrentReportIsActive(report.isActive)
      setCurrentReport(xReport)
      setSelectedReportType({label:report.reportTypeName,value:report.reportTypeId})
    }

    return (
      <button
        data-bs-toggle='modal'
        data-bs-target='#UpdateReportModal'
        className='btn btn-sm btn-info w-100'
        onClick={onClick}
      >
        {!isLoading && <span>{language["PAGES.MANAGEMENT.REPORT.EDITBUTTON"]}</span>}

        {isLoading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            {language["PAGES.MANAGEMENT.REPORT.PLEASEWAIT"]}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    )
  }
  const language = useIntl().messages
  const selectedLanguage = useIntl().locale

 const createReportSchema = Yup.object().shape({
    reportName: Yup.string()
      .typeError(language["PAGES.MANAGEMENT.REPORT.REPORTNAMEVALDATIONMESSAGE"].toString())
      .min(5, language["PAGES.MANAGEMENT.REPORT.REPORTNAMEVALDATIONCHARACTERMESSAGE"].toString())
      .required(language["PAGES.MANAGEMENT.REPORT.REPORTNAMEVALDATIONMESSAGE"].toString()),
      reportTypeId: Yup.number()
        .typeError(language["PAGES.MANAGEMENT.REPORT.REPORTTYPEVALIDATIONMESSAGE"].toString())      
        .required(language["PAGES.MANAGEMENT.REPORT.REPORTTYPEVALIDATIONMESSAGE"].toString()),
  })
 const updateReportSchema = Yup.object().shape({
      reportName: Yup.string()
        .typeError(language["PAGES.MANAGEMENT.REPORT.REPORTNAMEVALDATIONMESSAGE"].toString())
        .min(5, language["PAGES.MANAGEMENT.REPORT.REPORTNAMEVALDATIONCHARACTERMESSAGE"].toString())
        .required(language["PAGES.MANAGEMENT.REPORT.REPORTNAMEVALDATIONMESSAGE"].toString()),
        reportTypeId: Yup.number()
        .typeError(language["PAGES.MANAGEMENT.REPORT.REPORTTYPEVALIDATIONMESSAGE"].toString())      
        .required(language["PAGES.MANAGEMENT.REPORT.REPORTTYPEVALIDATIONMESSAGE"].toString()),
        isActive: Yup.boolean()
    })

  const DeleteCellRenderer = (params: any) => {
    const onClick = (e: any) => {
      e.stopPropagation()
      var xReport = params.data
      if (tableRows?.length !== 1 && tableRows[0]?.id !== xReport.id) {
        setLoading(true)
        mySwal
          .fire({
            title: language["PAGES.MANAGEMENT.REPORT.DELETEMESSAGETITLE"].toString(),
            icon: 'info',
            text: `${language["PAGES.MANAGEMENT.REPORT.DELETEMESSAGEDESCRIPTION"]} ${xReport.reportName}  ${language["PAGES.MANAGEMENT.REPORT.DELETEMESSAGEDESCRIPTIONCONFIRMATION"]} `,
            showDenyButton: true,
            confirmButtonText: language["PAGES.MANAGEMENT.REPORT.DELETEBUTTON"].toString(), 
            denyButtonText: language["PAGES.MANAGEMENT.REPORT.CANCELBUTTON"].toString(),
          })
          .then((result) => {
            if (result.isConfirmed) {
              DeleteReport(xReport.id)
                .then((response) => {
                  mySwal
                    .fire(language["PAGES.MANAGEMENT.REPORT.DELETESUCCESS"].toString(), language["PAGES.MANAGEMENT.REPORT.DELETESUCCESSMESSAGE"].toString(), 'success')
                    .then(() => {
                      GetData()
                      setLoading(false)
                    })
                })
                .catch(() => {
                  mySwal
                    .fire(language["PAGES.MANAGEMENT.REPORT.DELETEERROR"].toString(), language["PAGES.MANAGEMENT.REPORT.DELETEERRORMESSAGE"].toString(), 'error')
                    .then(() => {
                      setLoading(false)
                    })
                })
            }
            setLoading(false)
          })
      } else {
        setLoading(true)
        mySwal
          .fire({
            title: language["PAGES.MANAGEMENT.REPORT.DELETEMESSAGETITLE"].toString(),
            icon: 'info',
            text: ` ${xReport.reportName} ${language["PAGES.MANAGEMENT.REPORT.DELETELASTREPORTMESSAGE"]}`,
            showDenyButton: true,
            confirmButtonText: language["PAGES.MANAGEMENT.REPORT.DELETEBUTTON"].toString(),
            denyButtonText: language["PAGES.MANAGEMENT.REPORT.CANCELBUTTON"].toString(),
          })
          .then((result) => {
            if (result.isConfirmed) {
              DeleteReport(xReport.id)
                .then((response) => {
                  mySwal
                    .fire(language["PAGES.MANAGEMENT.REPORT.DELETESUCCESS"].toString(), language["PAGES.MANAGEMENT.REPORT.DELETESUCCESSMESSAGE"].toString(), 'success')
                    .then(() => {
                      GetData()
                      setLoading(false)
                    })
                })
                .catch(() => {
                  mySwal.fire(language["PAGES.MANAGEMENT.REPORT.DELETEERROR"].toString(), language["PAGES.MANAGEMENT.REPORT.DELETEERRORMESSAGESECOND"].toString(), 'error').then(() => {
                    setLoading(false)
                  })
                })
            }
            setLoading(false)
          })
      }
    }

    return (
      <button className='btn btn-sm btn-danger w-100' onClick={onClick}>
        {!isLoading && <span className='indicator-label'>{language["PAGES.MANAGEMENT.REPORT.DELETEBUTTON"]}</span>}
        {isLoading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            {language["PAGES.MANAGEMENT.REPORT.PLEASEWAIT"]}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    )
  }
  const IsActiveCellRenderer = (params: any) => {
    var isActive = params.data.isActive;
    return (
      <div className='mb-0'>
        <div className='form-check form-check-custom form-check-solid'>
          {isActive ? (
            <input
              className='form-check-input mt-2'
              type='checkbox'
              disabled
              value=''
              checked
            />
          ) : (
            <input className='form-check-input mt-2' type='checkbox' disabled value='' />
          )}
        </div>
      </div>
    )
  }
  //#endregion


  const GetReportTypeOptions = () => {
    GetReportTypes()
      .then((response) => {
        var reportTypes = response.data

        var xreportTypes: any[] = []
        reportTypes.forEach((type) => {
          if(selectedLanguage==='en')
          {xreportTypes.push({label: type.id === 1 ? language["PAGES.MANAGEMENT.REPORT.TABLEREPORT"].toString() : language["PAGES.MANAGEMENT.REPORT.GRAPHICREPORT"].toString(), value: type.id})}
          else{
            xreportTypes.push({label: type.typeName, value: type.id})
          }
        })
        setreportTypeOptions(xreportTypes)
      })
      .catch()
  }
  const GetData = () => {
    GetAllReports()
      .then((response) => {
        var zReports = response.data
        if(selectedLanguage==='en'){
          zReports.forEach((type)=>{
            type.reportTypeName= type.reportTypeId === 1 ? language["PAGES.MANAGEMENT.REPORT.TABLEREPORT"].toString() : language["PAGES.MANAGEMENT.REPORT.GRAPHICREPORT"].toString()
        })}
        setTableRows(zReports)
      })
      .catch((error) => {
        alert('Bir hata oluştu.' + error.response)
      })
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      GetIsPermissionGranted('/report-management').then((response) => {
        var isGranted = response.data
        setisGranted(isGranted)
         
        if (isGranted === false) {
          window.location.href = '/error/404'
        }
      })
      GetData()
      GetReportTypeOptions()
    }
    return () => {
      isMounted = false
    }
  }, [])
  const CreateReportFormik = useFormik({
    initialValues: initialCreateReportValues,
    validationSchema: createReportSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
       
      if (selectedReportType.value === 0) {
        mySwal.fire('Bilgilendirme !', 'Lütfen müşteri tipi seçiniz.', 'info')
      } else {
        values.reportTypeId = selectedReportType.value
        setLoading(true)
        setTimeout(() => {
          CreateReport(values as CreateReportModel)
            .then((reponse) => {
              mySwal
                .fire(language["PAGES.MANAGEMENT.REPORT.CREATESUCCESS"].toString(), language["PAGES.MANAGEMENT.REPORT.CREATESUCCESSMESSAGE"].toString(), 'success')
                .then(() => {
                  GetData()
                  resetForm()
                  setLoading(false)
                  window.location.reload()
                })
            })
            .catch((error) => {
              setLoading(false)
              setSubmitting(false)
              mySwal.fire(
                language["PAGES.MANAGEMENT.REPORT.CREATEERROR"].toString(),
                language["PAGES.MANAGEMENT.REPORT.CREATEERRORMESSAGE"].toString(),
                'error'
              )
              setStatus(error.message)
            })
        }, 1000)
      }
    },
  })
  const UpdateReportFormik = useFormik({
    enableReinitialize: true,
    initialValues: currentReport,
    validationSchema: updateReportSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      values.isActive = currentReportIsActive
      values.reportTypeId = selectedReportType.value
      setLoading(true)
      setTimeout(() => {
        UpdateReport(values as UpdateReportModel)
          .then((reponse) => {
            mySwal.fire(language["PAGES.MANAGEMENT.REPORT.UPDATESUCCESS"].toString(), language["PAGES.MANAGEMENT.REPORT.UPDATESUCCESSMESSAGE"].toString(), 'success').then(() => {
              GetData()
              resetForm()
              setLoading(false)
              window.location.reload()
            })
          })
          .catch((error) => {
            setLoading(false)
            setSubmitting(false)
            mySwal.fire(
              language["PAGES.MANAGEMENT.REPORT.UPDATEERROR"].toString(),
              language["PAGES.MANAGEMENT.REPORT.UPDATEERRORMESSAGE"].toString(),
              'error'
            )
            setStatus(error.message)
          })
      }, 1000)
    },
  })
  
  return (
    <>
      {isGranted ? (
        <>
          <>
           

            <div className='row alert alert-primary d-flex align-items-center pb-10 text-center'>
              <div className='col-md-10'>
              <div className='d-flex flex-column'>
                  <h5 className='mb-1'> {language["PAGES.MANAGEMENT.REPORT.PAGETITLE"]}</h5>
                  <span>
                    {language["PAGES.MANAGEMENT.REPORT.PAGEDESCRIPTION"]}
                  </span>
                </div>
              </div>
              <div className='col-md-2 '>
                <button
                  data-bs-toggle='modal'
                  data-bs-target='#NewReportModal'
                  className='btn btn-md btn-primary mt-5 float-right w-100'
                >
                  <i className='fa fa-plus' aria-hidden='true'></i>
                  {language["PAGES.MANAGEMENT.REPORT.DEFINENEWREPORTBUTTON"]}
                </button>
              </div>
            </div>
            <div className='card card-custom  card-stretch shadow '>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-12'>
                  <div className='ag-theme-alpine' style={{height: 600, width: '100%'}}>
                      <AgGridReact
                        frameworkComponents={{                         
                          isActiveCellRenderer:IsActiveCellRenderer,
                          editCellRenderer:EditCellRenderer,
                          deleteCellRenderer:DeleteCellRenderer
                        }}
                        defaultColDef={{
                          
                          sortable: true,
                          flex: 1,
                          filter: true,
                          resizable: true,
                        }}
                        onGridReady={onGridReady}
                        rowData={tableRows}
                      >
                        <AgGridColumn field='id' hide={true} />
                        <AgGridColumn field='reportTypeId' hide={true} />                        
                        <AgGridColumn field='reportName' headerName={language["PAGES.MANAGEMENT.REPORT.GRID.REPORTNAME"].toString()}  />
                        <AgGridColumn field='reportTypeName' headerName={language["PAGES.MANAGEMENT.REPORT.GRID.REPORTTYPE"].toString()}  />
                        
                        <AgGridColumn field='createdByStr' headerName={language["PAGES.MANAGEMENT.REPORT.GRID.CREATEDBY"].toString()}  />
                        <AgGridColumn field='createdDateStr' headerName={language["PAGES.MANAGEMENT.REPORT.GRID.CREATEDDATE"].toString()}  />
                        <AgGridColumn field='isActive' headerName={language["PAGES.MANAGEMENT.REPORT.GRID.ISACTIVE"].toString()} width={100} cellRenderer='isActiveCellRenderer' />
                        <AgGridColumn field='düzenle' pinned="right" width={125} headerName={language["PAGES.MANAGEMENT.REPORT.GRID.EDIT"].toString()} cellRenderer='editCellRenderer' />
                        <AgGridColumn field='sil' pinned="right" width={100} headerName={language["PAGES.MANAGEMENT.REPORT.GRID.DELETE"].toString()} cellRenderer='deleteCellRenderer' />
                      </AgGridReact>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal fade' tabIndex={-1} id='NewReportModal'>
              <div className='modal-dialog '>
                <div className='modal-content'>
                  <form
                    className='form w-100'
                    onSubmit={CreateReportFormik.handleSubmit}
                    noValidate
                  >
                    <div className='modal-header'>
                      <h5 className='modal-title'>{language["PAGES.MANAGEMENT.REPORT.NEWREPORT"]}</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </div>
                    <div className='modal-body text-center'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.REPORT.REPORTNAME"]}</label>
                        <input
                          placeholder={language["PAGES.MANAGEMENT.REPORT.NEWREPORTPLACEHOLDER"].toString()}
                          {...CreateReportFormik.getFieldProps('reportName')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid text-center',
                            {
                              'is-invalid':
                                CreateReportFormik.touched.reportName &&
                                CreateReportFormik.errors.reportName,
                            },
                            {
                              'is-valid':
                                CreateReportFormik.touched.reportName &&
                                !CreateReportFormik.errors.reportName,
                            }
                          )}
                          type='text'
                          name='reportName'
                          autoComplete='off'
                        />
                        {CreateReportFormik.touched.reportName &&
                          CreateReportFormik.errors.reportName && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {CreateReportFormik.errors.reportName}
                              </span>
                            </div>
                          )}
                      </div>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.REPORT.REPORTTYPE"]}</label>

                        <Select
                          {...CreateReportFormik.getFieldProps('reportTypeId')}
                          className={clsx(
                            '',
                            {
                              'is-invalid':
                                CreateReportFormik.touched.reportTypeId &&
                                CreateReportFormik.errors.reportTypeId,
                            },
                            {
                              'is-valid':
                                CreateReportFormik.touched.reportTypeId &&
                                !CreateReportFormik.errors.reportTypeId,
                            }
                          )}
                          onChange={(e) => {
                            setSelectedReportType(e as any)
                          }}
                          value={selectedReportType}
                          placeholder='Lütfen bir kullanıcı tipi seçiniz..'
                          options={reportTypeOptions}
                        />
                        {CreateReportFormik.touched.reportTypeId &&
                          CreateReportFormik.errors.reportTypeId && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {CreateReportFormik.errors.reportTypeId}
                              </span>
                            </div>
                          )}
                      </div>
                      {/* end::Form group */}
                    </div>
                    <div className='modal-footer'>
                      <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                        {language["PAGES.MANAGEMENT.GROUP.CANCELBUTTON"]}
                      </button>
                      <button type='submit' className='btn btn-primary'>
                        {!isLoading && <span>{language["PAGES.MANAGEMENT.REPORT.CREATEBUTTON"]}</span>}
                        {isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            {language["PAGES.MANAGEMENT.REPORT.PLEASEWAIT"]}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='modal fade' tabIndex={-1} id='UpdateReportModal'>
              <div className='modal-dialog '>
                <div className='modal-content'>
                  <form className='form w-100' onSubmit={UpdateReportFormik.handleSubmit}>
                    <div className='modal-header'>
                      <h5 className='modal-title'>{language["PAGES.MANAGEMENT.REPORT.REPORTUPDATE"]}</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </div>
                    <div className='modal-body text-center'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.REPORT.REPORTNAME"]}</label>
                        <input
                          placeholder={language["PAGES.MANAGEMENT.REPORT.NEWREPORTPLACEHOLDER"].toString()}
                          {...UpdateReportFormik.getFieldProps('reportName')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid text-center',
                            {
                              'is-invalid':
                                UpdateReportFormik.touched.reportName &&
                                UpdateReportFormik.errors.reportName,
                            },
                            {
                              'is-valid':
                                UpdateReportFormik.touched.reportName &&
                                !UpdateReportFormik.errors.reportName,
                            }
                          )}
                          type='text'
                          name='reportName'
                          autoComplete='off'
                        />
                        {UpdateReportFormik.touched.reportName &&
                          UpdateReportFormik.errors.reportName && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {UpdateReportFormik.errors.reportName}
                              </span>
                            </div>
                          )}
                      </div>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.REPORT.REPORTTYPE"]}</label>

                        <Select
                          {...UpdateReportFormik.getFieldProps('reportTypeId')}
                          className={clsx(
                            '',
                            {
                              'is-invalid':
                                UpdateReportFormik.touched.reportTypeId &&
                                UpdateReportFormik.errors.reportTypeId,
                            },
                            {
                              'is-valid':
                                UpdateReportFormik.touched.reportTypeId &&
                                !UpdateReportFormik.errors.reportTypeId,
                            }
                          )}
                          onChange={(e) => {
                            setSelectedReportType(e as any)
                          }}
                          value={selectedReportType}
                          placeholder='Lütfen bir kullanıcı tipi seçiniz..'
                          options={reportTypeOptions}
                        />
                        {UpdateReportFormik.touched.reportTypeId &&
                          UpdateReportFormik.errors.reportTypeId && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {UpdateReportFormik.errors.reportTypeId}
                              </span>
                            </div>
                          )}
                      </div>

                      <div className='row mb-10'>
                        <div className='col-sm-2  p-0'>
                          <p className='fs-6 fw-bolder '>{language["PAGES.MANAGEMENT.REPORT.ISACTIVE"]}</p>
                        </div>

                        <div className='col-sm-1  p-0'>
                          <div className='form-check form-check-custom form-check-solid '>
                            {
                              <input
                                {...UpdateReportFormik.getFieldProps('isActive')}
                                className='form-check-input'
                                type='checkbox'
                                name='isActive'
                                checked={currentReportIsActive}
                                onChange={(e) => {
                                  setCurrentReportIsActive(e.target.checked)
                                }}
                              />
                            }
                          </div>
                        </div>
                      </div>

                      {/* end::Form group */}
                    </div>
                    <div className='modal-footer'>
                      <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                        {language["PAGES.MANAGEMENT.REPORT.CLOSEBUTTON"]}
                      </button>
                      <button type='submit' className='btn btn-primary'>
                        {!isLoading && <span>{language["PAGES.MANAGEMENT.REPORT.UPDATEBUTTON"]}</span>}
                        {isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            {language["PAGES.MANAGEMENT.REPORT.PLEASEWAIT"]}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default ReportManagement
