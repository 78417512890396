import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../helpers'


const Objects: FC = () => {


  return (
    <div className='mb-0'>
      <div className='mb-7'>
        <h3 className='fw-bold text-center mb-3'>Grafik Görünümü</h3>
        <div className='overlay overflow-hidden border border-4 border-primary p-1 rounded'>
          <div className='overlay-wrapper rounded'>
            <img
              src={toAbsoluteUrl('/media/objects/pie-chart.png')}
              alt='demo'
              className='w-100 rounded'
            />
          </div>
          <div className='overlay-layer bg-dark bg-opacity-10'>
            <button              
              type='button'
              className='btn btn-primary shadow'
              data-bs-toggle='modal'
              data-bs-target='#graphic-builder-modal'
            >
              Oluştur
            </button>
          </div>
        </div>
        
      </div>
      <div className='mb-7'>
        <h3 className='fw-bold text-center mb-3'>Tablo Görünümü</h3>
        <div className='overlay overflow-hidden border border-4 border-primary p-2 rounded'>
          <div className='overlay-wrapper rounded'>
            <img
              src={toAbsoluteUrl('/media/objects/table.png')}
              alt='demo'
              className='w-100 rounded'
            />
          </div>
          <div className='overlay-layer bg-dark bg-opacity-10'>
            <a href='/table-report-builder'
              type='button'
              className='btn btn-primary shadow'
             
            >
              Oluştur
            </a>
          </div>
        </div>
      </div>

      <div className='mb-7'>
        <h3 className='fw-bold text-center mb-3'>Pano</h3>
        <div className='overlay overflow-hidden border border-4 border-primary p-2 rounded'>
          <div className='overlay-wrapper rounded'>
            <img
              src={toAbsoluteUrl('/media/objects/pano.png')}
              alt='demo'
              className='w-100 rounded'
            />
          </div>
          <div className='overlay-layer bg-dark bg-opacity-10'>
            <a href='/panel-builder' className='btn btn-primary shadow'>
              Oluştur
            </a>
          </div>
        </div>
      </div>
    
    </div>
  )
}

export {Objects}
