import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_IS_PERMISSION_GRANTED_URL = `${API_URL}/Auth/IsPermissionGranted`
export const GET_IS_REST_USER_URL = `${API_URL}/Auth/IsRestUser`

export function GetIsPermissionGranted(url: string) {
    return axios.post<boolean>(GET_IS_PERMISSION_GRANTED_URL, {url:url})
  }
  export function IsRestUser(email:string) {
    return axios.post<boolean>(GET_IS_REST_USER_URL,{email:email})
  }