import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {ReportPage} from '../pages/report/ReportPage'
import {PanelBuilder} from '../pages/builders/panel-builder/PanelBuilder'
import QueryBuilder from '../pages/builders/query-builder/QueryBuilder'
import {TableReportBuilder} from '../pages/builders/table-report-builder/TableReportBuilder'
import {GraphicReportBuilder} from '../pages/builders/graphic-report-builder/GraphicReportBuilder'
import QueryBuilderList from '../pages/builders/query-builder/QueryBuilderList'
import CustomerManagement from '../pages/management/customer-management/CustomerManagement'
import {Account} from '../modules/profile/components/account/Account'
import UserManagement from '../pages/management/user-management/UserManagement'
import MenuItemManagement from '../pages/management/menu-item-management/MenuItemManagement'
import GroupManagement from '../pages/management/group-management/GroupManagement'
import Settings from '../pages/settings-page/Settings'
import ReportManagement from '../pages/management/report-management/ReportManagement'
import CustomerReportManagement from '../pages/management/customer-report-management/CustomerReportManagement'
import ZReportPage from '../pages/zreport/ZReportPage'
import Archive from '../pages/archive/Archive'
import {ForgotPassword} from '../modules/auth/components/ForgotPassword'
import ResetPassword from '../modules/auth/components/ResetPassword'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />

        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/report-page/:secretKey' component={ZReportPage} />
        <Route path='/graphic-report-builder/:graphicTypeId' component={GraphicReportBuilder} />
        {/* <Route path='/table-report-builder' component={TableReportBuilder} />
        <Route path='/panel-builder' component={PanelBuilder} />
        <Route path='/query-builder' component={QueryBuilder} />
        <Route path='/query-builder-list' component={QueryBuilderList} /> */}
        <Route path='/customer-management' component={CustomerManagement} />
        <Route path='/user-management' component={UserManagement} />
        <Route path='/group-management' component={GroupManagement} />
        <Route path='/menu-item-management' component={MenuItemManagement} />
        <Route path='/report-management' component={ReportManagement} />
        <Route path='/archive' component={Archive} />
        <Route
          path='/customer-report-management/:customerId'
          component={CustomerReportManagement}
        />
        <Route path='/settings-page' component={Settings} />
        <Route path='/user-profile' component={ProfilePage} />

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
