import axios from 'axios'
import { CreateMenuItemModel, MenuItemModel, UpdateMenuItemModel } from './Models'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_ALL_MENUITEMS_URL = `${API_URL}/MenuItems/getall`
export const MENUITEMS_URL = `${API_URL}/MenuItems`
export const DELETE_MENUITEM_URL = `${API_URL}/MenuItems?deleteMenuItemId=`

export function GetAllMenuItems() {
    return axios.get<MenuItemModel[]>(GET_ALL_MENUITEMS_URL)
  }
  export function CreateMenuItem(menuItem: CreateMenuItemModel) {
    return axios.post(MENUITEMS_URL, menuItem)
  }
  export function UpdateMenuItem(menuItem: UpdateMenuItemModel) {    
    return axios.put(MENUITEMS_URL, menuItem)
  }
  export function DeleteMenuItem(menuItemId: number) {
    return axios.delete(DELETE_MENUITEM_URL + menuItemId)
  }