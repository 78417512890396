/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
// import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login} from '../redux/AuthCRUD'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import { useIntl } from 'react-intl'
import { setLanguage } from '../../../../_metronic/i18n/Metronici18n'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'


const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/



export function Login() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const language = useIntl().messages
  const intl = useIntl()
  
  const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email(language["AUTH.LOGIN.EMAILVALIDATIONFORMATMESSAGE"].toString())
    .min(3, language["AUTH.LOGIN.EMAILVALIDATIONMINCHARACTERMESSAGE"].toString())
    .max(50, language["AUTH.LOGIN.EMAILVALIDATIONMAXCHARACTERMESSAGE"].toString())
    .required(language["AUTH.LOGIN.EMAILVALIDATIONMESSAGE"].toString()),
  password: Yup.string()
    .min(3, language["AUTH.LOGIN.PASSWORDVALIDATIONMINCHARACTERMESSAGE"].toString())
    .max(50, language["AUTH.LOGIN.PASSWORDVALIDATIONMAXCHARACTERMESSAGE"].toString())
    .required(language["AUTH.LOGIN.PASSWORDVALIDATIONMESSAGE"].toString()),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        login(values.email, values.password)
          .then(({data}) => {
            console.log(data)
            setLoading(false)
            dispatch(auth.actions.login(data.data.token))
          })
          .catch((error) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(language["AUTH.LOGIN.LOGINERRORMESSAGE"].toString())
          })
      }, 1000)
    },
  })

  
  return (
    
    <form
    style={{
      position:'relative'
    }}
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <select style={{
        position:'absolute',
        right:'-20px',
        borderRadius:'10px',
        padding:'4px'
      }} 
      value={intl.locale}
        onChange={(e)=>{
          setLanguage(e.target.value)
        }}
      name="language">
        <option value="tr">TR</option>
        <option value="en">EN</option>
      </select>
      {/* begin::Logo */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-0 pb-lg-0'>
        <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-45px' />
        </a>
      </div>
      {/* end::Logo */}
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{language["AUTH.LOGIN.TITLE"]}</h1>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div></div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>{language["AUTH.INPUT.EMAIL"]}</label>
        <input
          placeholder={language["AUTH.LOGIN.EMAIL.PLACEHOLDER"].toString()}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>{language["AUTH.INPUT.PASSWORD"]}</label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type='password'
          placeholder={language["AUTH.LOGIN.PASSWORD.PLACEHOLDER"].toString()}
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        <Link
          to='/auth/forgot-password'
          className='link-primary fs-7 fw-bolder'
          style={{marginLeft: '5px'}}
        >
          {language["AUTH.FORGOT.TITLE"]}
        </Link>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{language["AUTH.LOGIN.BUTTON"]}</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {language["AUTH.GENERAL.PLEASEWAIT"]}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
