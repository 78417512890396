import axios from 'axios'
import {ArchiveFileModel, ArchiveModel, ArchiveParamsModel} from './Models'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_ARCHIVES_URL = `${API_URL}/Archive/GetArchives`
export const GET_SINGLE_ARCHIVE_URL = `${API_URL}/Archive/GetSingleArchive`
export const DOWNLOAD_ARCHIVE_PDF_URL = `${API_URL}/Archive/DownloadArchivePDF`
export const DOWNLOAD_ARCHIVE_ZIP_URL = `${API_URL}/Archive/DownloadArchiveZIP`

export function GetArchives(params: ArchiveParamsModel) {
  return axios.post<ArchiveModel[]>(GET_ARCHIVES_URL, params)
}
export function GetSingleArchive(archiveId: number) {
  return axios.post<ArchiveFileModel>(GET_SINGLE_ARCHIVE_URL, {archiveId: archiveId})
}

export function DownloadArchivePDF(type: string, refNo: string) {
  return axios.post<ArchiveFileModel>(DOWNLOAD_ARCHIVE_PDF_URL, {
    type: type,
    refNo: refNo,
  })
}
export function DownloadArchiveZIP(type: string, refNo: string) {
  return axios.post<ArchiveFileModel>(DOWNLOAD_ARCHIVE_ZIP_URL, {
    type: type,
    refNo: refNo,
  })
}
