import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {BarChartBuilder} from '../../../modules/graphic-modules/builders/charts/bar/BarChartBuilder'
import LineChartBuilder from '../../../modules/graphic-modules/builders/charts/line/LineChartBuilder'
import {PieChartBuilder} from '../../../modules/graphic-modules/builders/charts/pie/PieChartBuilder'
import {RadarChartBuilder} from '../../../modules/graphic-modules/builders/charts/radar/RadarChartBuilder'
import {
  ICreateReport,
  SaveReport,
} from '../../../modules/wizards/components/api/GraphicReportBuilderApiOperations'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { GetIsPermissionGranted } from '../../general/GeneralApiOperations'

const mySwal = withReactContent(Swal)

export function GraphicReportBuilder() {
  const graphicreportBuilderBreadCrumbs: Array<PageLink> = [
    {
      title: 'Yeni Nesne',
      path: '/builders',
      isSeparator: false,
      isActive: false,
    },
  ]
  const {graphicTypeId} = useParams<{graphicTypeId: string}>()
  const [isGranted, setisGranted] = useState(false)
  const [currentGraphicType, setcurrentGraphicType] = useState<string>('')
  const [isSaving, setIsSaving] = useState(false)
  
  useEffect(() => {
    GetIsPermissionGranted('/graphic-report-builder').then((response) => {
      var isGranted = response.data
      setisGranted(isGranted)
       
      if (isGranted === false) {
        window.location.href = "/error/404";
      }
    })
  }, [graphicTypeId])


  const SaveGraphicReport = () => {
     ;
    setIsSaving(true)
    var xdataCubaName = document.querySelector<HTMLInputElement>(
      'input[name="dataCubeName"]'
    )!.value
    var xreportName = document.querySelector<HTMLInputElement>('input[name="reportName"]')!.value
    var xgraphicOption = document.querySelector<HTMLInputElement>(
      'input[name="graphicOption"]'
    )!.value
    var xgraphicType = document.querySelector<HTMLInputElement>('input[name="graphicType"]')!.value

    const createReportData: ICreateReport = {
      reportName: xreportName,
      dataCubeName: xdataCubaName,
      graphicOptionJson: xgraphicOption,
      graphicTypeId: parseInt(xgraphicType),
      reportSqlText: '',
      reportTypeId: 3,
    }
    if (
      xdataCubaName !== '' &&
      xreportName !== '' &&
      xgraphicOption !== '' &&
      xgraphicType !== ''
    ) {
       ;
      SaveReport(createReportData)
      
        .then(() => {
          setIsSaving(false)
          mySwal.fire('Başarılı !', '<p>Rapor başarıyla kaydedildi</p>', 'success').then(()=>{

            window.location.reload()
          })
        })
        .catch(() => {
          setIsSaving(false)
          mySwal.fire('Hata !', '<p>Rapor kaydedilirken bir hata oluştu.</p>', 'error')
        })
    } else {
      mySwal.fire(
        'Bilgi !',
        '<p>Raporu kaydedebilmek için lütfen gerekli tüm alanları doldurunuz.</p>',
        'info'
      ).then(()=>{
        setIsSaving(false)
      })
    }
  }
  return (

    <>
{
  isGranted ? 
  (<>
  <div className='row'>
      <div className='col-xxl-12'>
        <div className='card text-center'>
          <div className='card-header '>
            <h5 className='card-title'>Grafik Raporu Oluştur</h5>
            <div className='card-toolbar'>
              <button
                onClick={() => SaveGraphicReport()}
                type='button'
                className='btn btn-md btn-primary'
              >
                {!isSaving && <span className='indicator-label'>Kaydet</span>}
                {isSaving && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Lütfen Bekleyin...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>

          <div className='card-body'>
            <PageTitle breadcrumbs={graphicreportBuilderBreadCrumbs}>Grafik Görünümü</PageTitle>
            
            <div className='row'>
              <div className='col-md-12'>
                {(() => {
                  switch (graphicTypeId) {
                    case '1':
                      return <PieChartBuilder />
                    case '2':
                      return <BarChartBuilder />
                    case '3':
                      return <LineChartBuilder />
                    case '4':
                      return <RadarChartBuilder />
                    default:
                      break
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
  :
  (<>
   
  </>)
}
</>
    
  )
}
