import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {GraphicReportBuilderWizard} from '../wizards/components/GraphicReportBuilderWizard'
import {Step3} from '../wizards/components/steps/Step3'

const GraphicReportBuilderModal = () => {
  return (
    <div className='modal fade' tabIndex={-1} id='graphic-builder-modal'>
      <div className='modal-dialog  modal-fullscreen'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Grafik Görünümü</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <div className='w-100'>
              <div className='pb-10 pb-lg-12 text-center'>
                <h2 className='fw-bolder text-dark'>Grafik Seçimi</h2>

                <div className='text-gray-400 fw-bold fs-6'>
                  Lütfen kullanılacak grafik rapor türünü seçiniz.
                </div>
              </div>

              <div className='row text-center'>
                <div className='col-sm-2 mx-auto'>
                  <div className='card card-custom  card-stretch-50 shadow mb-5 w-100 '>
                    <div className='card-header  card-header-stretch-33 text-center '>
                      <h3 className='card-title'>Pasta Grafik</h3>
                    </div>
                    <div className='card-body'>
                      <div className='overlay overflow-hidden border border-4 border-primary p-1 rounded'>
                        <div className='overlay-wrapper rounded'>
                          <img
                            src={toAbsoluteUrl('/media/chart-types/pie-chart.png')}
                            alt='demo'
                            className='w-100 rounded'
                          />
                        </div>
                        <div className='overlay-layer bg-dark bg-opacity-10'>
                          <a href='/graphic-report-builder/1' className='btn btn-primary shadow'>
                            Kullan
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='card-footer'>Pasta grafik oluşturmak için bunu kullanın.</div>
                  </div>
                </div>
                <div className='col-sm-2 mx-auto'>
                  <div className='card card-custom  card-stretch shadow mb-5 w-100 '>
                    <div className='card-header  card-header-stretch-33 '>
                      <h3 className='card-title'>Sütun Grafik</h3>
                    </div>
                    <div className='card-body'>
                      <div className='overlay overflow-hidden border border-4 border-primary p-1 rounded'>
                        <div className='overlay-wrapper rounded'>
                          <img
                            src={toAbsoluteUrl('/media/chart-types/bar-chart.png')}
                            alt='demo'
                            className='w-100 rounded'
                          />
                        </div>
                        <div className='overlay-layer bg-dark bg-opacity-10'>
                          <a href='/graphic-report-builder/2' className='btn btn-primary shadow'>
                            Kullan
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='card-footer'>Sütun grafik oluşturmak için bunu kullanın.</div>
                  </div>
                </div>
                <div className='col-sm-2 mx-auto'>
                  <div className='card card-custom  card-stretch shadow mb-5 w-100 '>
                    <div className='card-header  card-header-stretch-33 '>
                      <h3 className='card-title'>Çizgi Grafik</h3>
                    </div>
                    <div className='card-body'>
                      <div className='overlay overflow-hidden border border-4 border-primary p-1 rounded'>
                        <div className='overlay-wrapper rounded'>
                          <img
                            src={toAbsoluteUrl('/media/chart-types/line-chart.png')}
                            alt='demo'
                            className='w-100 rounded'
                          />
                        </div>
                        <div className='overlay-layer bg-dark bg-opacity-10'>
                          <a href='/graphic-report-builder/3' className='btn btn-primary shadow'>
                            Çok yakında
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='card-footer'>Çizgi grafik oluşturmak için bunu kullanın.</div>
                  </div>
                </div>
                <div className='col-sm-2 mx-auto'>
                  <div className='card card-custom  card-stretch shadow mb-5 w-100 '>
                    <div className='card-header  card-header-stretch-33 '>
                      <h3 className='card-title'>Radar Grafik</h3>
                    </div>
                    <div className='card-body'>
                      <div className='overlay overflow-hidden border border-4 border-primary p-1 rounded'>
                        <div className='overlay-wrapper rounded'>
                          <img
                            src={toAbsoluteUrl('/media/chart-types/radar.png')}
                            alt='demo'
                            className='w-100 rounded'
                          />
                        </div>
                        <div className='overlay-layer bg-dark bg-opacity-10'>
                          <a href='/graphic-report-builder/4' className='btn btn-primary shadow'>
                            Çok yakında
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='card-footer'>Radar grafik oluşturmak için bunu kullanın.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
              Kapat
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GraphicReportBuilderModal
