import React from 'react'

const RadarChartBuilder = () => {
    return (
        <div className='card card-custom  card-stretch shadow mb-5 w-100 '>
        <div className='card-header '>
          <h3 className='card-title  '>Grafik Ayarları</h3>
        </div>
        <div className='card-body'>
          <div className='text-center'>
            <h3>Radar Grafik Tasarım Alanı</h3>
          </div>
        </div>
        <div className='card-footer'>
          Veri sütunlarını sürükleyip bırakarak grafiği oluşturabilirsiniz.
        </div>
      </div>
    )
}
 
export {RadarChartBuilder}
