import React, {useEffect, useState} from 'react'

import ReactECharts from 'echarts-for-react'
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd'
import {SelectItem} from '../../../../api-models/SelectItem'
import {Datum, Legend, OptionModel, Title} from '../models/OptionModel'

import Select, {SingleValue} from 'react-select'
import {
  DataCubeData,
  GetAllCollectionNames,
  GetReportBuilderDataByCollectionName,
} from '../../../../wizards/components/api/GraphicReportBuilderApiOperations'
import {Serie} from './ChartModels'
import {GetSeriesFromData, GetTimeLineOption, GroupArrayOfObjects} from './Operations'
import {BarChartDataModel, DataSet} from './BarChartDataModel'

const initialChartData = {
  dataCubeName: '[Veri Kümesi İsmi]',
  dataCubeColumns: [{label: 'Test Column', value: 'Test Column'}],
  dataCubeData: '',
}

const BarChartBuilder = () => {
  const [xAxisColumns, setxAxisColumns] = useState<SelectItem[]>([])
  const [dataColumns, setDataColumns] = useState<SelectItem[]>([])
  const [timeLineColumns, setTimeLineColumns] = useState<SelectItem[]>([])
  const [reportName, setReportName] = useState<string>('')
  const [selectedDataCube, setSelectedDataCube] = useState<string>('')
  const [dataCubeName, setDataCubeName] = useState<string>('')
  const [dataCubes, setDataCubes] = useState<SelectItem[]>([])
  const [currentReportBuilderData, setCurrentReportBuilderData] =
    useState<DataCubeData>(initialChartData)
  const [dataCubeColumns, setDataCubeColumns] = useState<SelectItem[]>([])
  const [xAxisData, setxAxisData] = useState<string[]>([])
  const [series, setSeries] = useState<Serie[]>([])
  const [isTimeLine, setIsTimeLine] = useState<boolean>(false)
  const [timeLineOption, setTimeLineOption] = useState<object>({})

  const [chartLegend, setChartLegend] = useState<Legend>({
    orient: 'vertical',
    left: 'right',
    top: 'top',
  })
  const [chartTitle, setChartTitle] = useState<Title>({
    text: '[Rapor Başlığı]',
    subtext: '',
    left: 'center',
  })

  const handleOnChangeDataCube = (value: any) => {
    setSelectedDataCube(value)
    setDataCubeName(value)
  }

  const handleOnChangeReportName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReportName(e.target.value)
    setChartTitle({
      text: e.target.value,
      subtext: '',
      left: 'center',
    })
  }

  const GetBuilderData = () => {
    GetReportBuilderDataByCollectionName(selectedDataCube)
      .then((response) => {
        setCurrentReportBuilderData(response.data)
        setDataCubeColumns(response.data.dataCubeColumns)
        setxAxisColumns([])
        setDataColumns([])
        setSeries([])
      })
      .catch((error) => {
        alert(error.response.message)
      })
  }

  let option = {
    title: chartTitle,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    toolbox: {
      orient: 'vertical',
      top: 'center',
      left: 'left',
      show: true,
      feature: {
        dataView: {
          show: true,
          readOnly: true,
        },
        saveAsImage: {
          show: true,
        },
      },
    },
    legend: chartLegend,
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
    },
    yAxis: {
      type: 'value',
    },
    series: series,
  }
  const onDragEnd = (result: DropResult) => {
    const {source, destination} = result
    console.log(result)
    if (!destination) return
    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    let add,
      activeColumns = dataCubeColumns,
      xAxisCols = xAxisColumns.map((p) => p),
      dataCols = dataColumns.map((p) => p),
      tLineColumns = timeLineColumns.map((p) => p)

    //#region YAxisColumnDestination
    if (
      source.droppableId === 'ColumnSource' &&
      destination.droppableId === 'YAxisColumnDestination'
    ) {
      add = activeColumns[source.index]
      activeColumns.splice(source.index, 1)
      xAxisCols.push(add)
    } else if (
      source.droppableId === 'YAxisColumnDestination' &&
      destination.droppableId === 'ColumnSource'
    ) {
      add = xAxisColumns[source.index]
      xAxisColumns.splice(source.index, 1)
      xAxisCols.splice(source.index, 1)
      activeColumns.push(add)
    }
    //#endregion

    //#region DataColumnDestination
    if (
      source.droppableId === 'ColumnSource' &&
      destination.droppableId === 'DataColumnDestination'
    ) {
      add = activeColumns[source.index]
      activeColumns.splice(source.index, 1)
      dataCols.push(add)
    } else if (
      source.droppableId === 'DataColumnDestination' &&
      destination.droppableId === 'ColumnSource'
    ) {
      add = dataColumns[source.index]
      dataColumns.splice(source.index, 1)
      dataCols.splice(source.index, 1)
      activeColumns.push(add)
    }
    //#endregion

    //#region TimeLineColumnDestination

    if (
      source.droppableId === 'ColumnSource' &&
      destination.droppableId === 'TimeLineColumnDestination'
    ) {
      add = activeColumns[source.index]
      activeColumns.splice(source.index, 1)
      tLineColumns.push(add)
    } else if (
      source.droppableId === 'TimeLineColumnDestination' &&
      destination.droppableId === 'ColumnSource'
    ) {
      add = dataColumns[source.index]
      timeLineColumns.splice(source.index, 1)
      tLineColumns.splice(source.index, 1)
      activeColumns.push(add)
    }
    //#endregion
    setDataCubeColumns(activeColumns)
    setxAxisColumns(xAxisCols)
    setDataColumns(dataCols)
    setTimeLineColumns(tLineColumns)
  }

  useEffect(() => {
    let isMounted = true
    GetAllCollectionNames()
      .then((response) => {
        if (isMounted) {
          var collections = response.data.map((collectionName) => {
            return {label: collectionName, value: collectionName}
          })
          collections = collections.filter((collection)=>{
            return (collection.label.toLocaleLowerCase()!=='main')
          })
          setDataCubes(collections)
        }
      })
      .catch((error) => {
        console.log(error.response.message)
      })
    if (isMounted) {
      if (selectedDataCube !== '') {
        if (xAxisColumns.length > 0) {
          setxAxisData(
            GetUniqueColumnsOfDataByColumName(
              xAxisColumns[0].value,
              currentReportBuilderData.dataCubeData
            )
          )
        }

        if (xAxisColumns.length > 0 && dataColumns.length > 0) {
           
          var chartData: BarChartDataModel = GetSeriesFromData(
            currentReportBuilderData.dataCubeData,
            xAxisColumns[0].label,
            dataColumns[0].label,
           "sayı"
          )
          if(chartData.datasets[0].data.filter(x=>x!==undefined).length === 0)
          {
            var chartData: BarChartDataModel = GetSeriesFromData(
              currentReportBuilderData.dataCubeData,
              xAxisColumns[0].label,
              dataColumns[0].label,
             "kıymet"
            )
          }
          var series: Serie[] = []
          chartData.datasets.forEach((dataSet: DataSet) => {
            var serie: Serie = {
              name: dataSet.label === null ? 'boş' : dataSet.label.toString().trim(),
              type: 'bar',
              stack: 'total',
              label: {
                show: true,
              },
              emphasis: {
                focus: 'series',
              },
              data: dataSet.data,
            }
            series.push(serie)
          })
          setSeries(series)
          if (timeLineColumns.length > 0) {
            var timeLineValueArray = GetUniqueColumnsOfDataByColumName(
              timeLineColumns[0].label.toLocaleLowerCase(),
              currentReportBuilderData.dataCubeData.toLocaleLowerCase()
            )
            var groupedArray: Array<object[]> = GroupArrayOfObjects(
              JSON.parse(currentReportBuilderData.dataCubeData.toLocaleLowerCase()),
              timeLineColumns[0].label.toLocaleLowerCase()
            )

            var dataColumnValueArray = GetUniqueColumnsOfDataByColumName(
              dataColumns[0].label,
              currentReportBuilderData.dataCubeData
            )
            //bu diziye timeLine a eklemek için seriler doldurulacak
            var dataColumnsArray: Array<object[]> = []
            //Seçilen veri kolonunun içinde yer alan tüm tekil değerler için seri oluşturuluyor
            dataColumnValueArray.map((dataColumnValue) => {
              //Her bir tekil veri değeri için timeLineColumn içerisindeki değerlere göre dizi oluşturuluyor
              var dataColumnDataArray: Array<object> = []
              timeLineValueArray.forEach((timeLineValue) => {
                //her bir timeLineColumn value su için gruplanmış data da veri kolonundanki anlık değere eşit olan kayıtları getiriyoruz
                var filteredDatas = groupedArray[timeLineValue].filter((x) => {
                  type objectKey = keyof typeof x
                  return (
                    x[dataColumns[0].label.toLocaleLowerCase() as objectKey] === dataColumnValue
                  )
                })
                //Xais e seçilen kolonun tekil değerlerinin her biri için timelinecolumn objelerine veri dizileri atılıyor
                var dataColumnArrayObject: Array<object> = []
                filteredDatas.forEach((filteredData) => {
                  type objectKey = keyof typeof filteredData
                  dataColumnArrayObject.push({
                    name: filteredData[xAxisColumns[0].label.toLocaleLowerCase() as objectKey],
                    value: filteredData['sayı' as objectKey],
                  })
                })
                dataColumnDataArray.push({
                  [timeLineValue]: dataColumnArrayObject,
                })
              })

              dataColumnsArray.push(dataColumnDataArray)
            })

             
            var timeLineOption = GetTimeLineOption(
              reportName,
              dataColumnsArray,
              dataColumnValueArray,
              timeLineValueArray
            )
            setTimeLineOption(timeLineOption)
            console.log(timeLineOption)
            setIsTimeLine(true)
          } else {
            setIsTimeLine(false)
          }
        }
      }
    }
    return () => {
      isMounted = false
    }
  }, [xAxisColumns, dataColumns, timeLineColumns])
  return (
    <div className='row'>
      <div className='row mb-5'>
        <div className='col-md-12 text-center'>
          <div className='card card-custom  card-stretch shadow mb-5 w-100  h-100'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-12'>
                  <h5>Rapor İsmi :</h5>
                  <input
                    type='text'
                    name='reportName'
                    required={true}
                    placeholder='Bir rapor ismi giriniz...'
                    className='form-control text-center'
                    onChange={(e) => handleOnChangeReportName(e)}
                  />
                  {!reportName && <p className='text-danger'>Rapor ismi zorunludur</p>}
                </div>
              </div>
              <div className='row '>
                <h5>Veri Kümesi Seçimi :</h5>
                <div className='col-md-8 mt-5 mb-5'>
                  <Select
                    name='dataCubeName'
                    onChange={(e) => handleOnChangeDataCube(e?.value)}
                    placeholder='Lütfen bir veri kümesi seçiniz..'
                    options={dataCubes}
                  />
                </div>
                <div className='col-md-4 mt-5 mb-5'>
                  {selectedDataCube === '' ? (
                    <button className='btn btn-info w-75' disabled>
                      Getir
                    </button>
                  ) : (
                    <button className='btn btn-info w-75' onClick={() => GetBuilderData()}>
                      Getir
                    </button>
                  )}
                </div>
                <input hidden name='dataCubeName' value={dataCubeName} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
        <div className='row '>
          <div className='col-md-3'>
            <div className='card card-custom  card-stretch shadow mb-5 w-100  h-100'>
              <div className='card-header '>
                <h3 className='card-title'>{currentReportBuilderData.dataCubeName}</h3>
              </div>
              <Droppable droppableId='ColumnSource'>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className='card-body list-group text-center '
                  >
                    {dataCubeColumns.length > 0 ? (
                      dataCubeColumns.map((column, index) => {
                        return (
                          <Draggable draggableId={column.value} index={index}>
                            {(prrovided) => (
                              <div
                                key={index}
                                className='list-group-item text-center'
                                {...prrovided.draggableProps}
                                {...prrovided.dragHandleProps}
                                ref={prrovided.innerRef}
                              >
                                {column.value}
                              </div>
                            )}
                          </Draggable>
                        )
                      })
                    ) : (
                      <p className='text-muted '>
                        Bir veri kümesi getirdiğinizde kolonları burada görünecek
                      </p>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <br />
              <div className='card-footer text-muted text-center'>
                Veri sütunlarını sağdaki alana sürükleyip bırakarak grafiği oluşturabilirsiniz.
              </div>
            </div>
          </div>
          <div className='col-md-9'>
            <div className='card card-xxl shadow mb-5 w-100  h-100' style={{height: '1000px;'}}>
              <div className='card-body'>
                <div className='row '>
                  <div className='col-sm-12 '>
                    <div className='card'>
                      <div className='row'>
                        <div className='col-md-4 border border-primary'>
                          <Droppable droppableId='YAxisColumnDestination'>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className='card-body list-group border-primary'
                              >
                                {snapshot.isDraggingOver ? (
                                  <span className='text-muted'>Buraya bırakın</span>
                                ) : (
                                  <span className='text-muted'>
                                    X ekseninde görünmesini istediğiniz kolonu buraya sürükleyip
                                    bırakabilirsiniz
                                  </span>
                                )}
                                {xAxisColumns.map((column, index) => {
                                  return (
                                    <Draggable draggableId={column.value} index={index}>
                                      {(prrovided) => (
                                        <div
                                          key={index}
                                          className='list-group-item text-center'
                                          {...prrovided.draggableProps}
                                          {...prrovided.dragHandleProps}
                                          ref={prrovided.innerRef}
                                        >
                                          {column.value}
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                })}

                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                        <div className='col-md-4 border border-warning ml-5'>
                          <Droppable droppableId='TimeLineColumnDestination'>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className='card-body list-group border-primary'
                              >
                                {snapshot.isDraggingOver ? (
                                  <span className='text-muted'>Buraya bırakın</span>
                                ) : (
                                  <span className='text-muted'>
                                    Zaman Çizgisi için veri kolonunu buraya sürükleyip
                                    bırakabilirsiniz
                                  </span>
                                )}
                                {timeLineColumns.map((column, index) => {
                                  return (
                                    <Draggable draggableId={column.value} index={index}>
                                      {(prrovided) => (
                                        <div
                                          key={index}
                                          className='list-group-item text-center'
                                          {...prrovided.draggableProps}
                                          {...prrovided.dragHandleProps}
                                          ref={prrovided.innerRef}
                                        >
                                          {column.value}
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                })}

                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>

                        <div className='col-md-4 border border-primary ml-5'>
                          <Droppable droppableId='DataColumnDestination'>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className='card-body list-group border-primary'
                              >
                                {snapshot.isDraggingOver ? (
                                  <span className='text-muted'>Buraya bırakın</span>
                                ) : (
                                  <span className='text-muted'>
                                    Veri kolonunu buraya sürükleyip bırakabilirsiniz
                                  </span>
                                )}
                                {dataColumns.map((column, index) => {
                                  return (
                                    <Draggable draggableId={column.value} index={index}>
                                      {(prrovided) => (
                                        <div
                                          key={index}
                                          className='list-group-item text-center'
                                          {...prrovided.draggableProps}
                                          {...prrovided.dragHandleProps}
                                          ref={prrovided.innerRef}
                                        >
                                          {column.value}
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                })}

                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='separator separator-dotted border-dark my-10'></div>
                <div className='row '>
                  <div className='col-lg-12 text-center align-items-center'>
                    {isTimeLine ? (
                      <ReactECharts option={timeLineOption} theme={''} style={{height: '500px'}} />
                    ) : (
                      <ReactECharts option={option} theme={''} style={{height: '500px'}} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=' text-muted text-center mt-10 mb-10'>
          Veri sütunlarını sürükleyip bırakarak grafiği oluşturabilirsiniz.
        </div>
        {isTimeLine ? (
          <input
            hidden
            name='graphicOption'
            id='graphicOption'
            value={JSON.stringify(timeLineOption)}
          />
        ) : (
          <input hidden name='graphicOption' id='graphicOption' value={JSON.stringify(option)} />
        )}

        <input hidden name='graphicType' value='1' />
      </DragDropContext>
    </div>
  )
}
export function GetUniqueColumnsOfDataByColumName(columnName: string, data: string) {
  //Veri parse edildi
  var convertedData = JSON.parse(data.toLocaleLowerCase())
  //Girilen column name in tüm tabloda içerdiği kayıtlar çekiliyor
  var valueArray = getFields(convertedData, columnName.toLocaleLowerCase().trim())
  //Girilen column name'in içerdiği veriler distinct mantığıyla tekrar etmeyecek bir şekilde listeleniyor ver geri dönülüyor
  var uniqueColumns = valueArray.filter(onlyUnique)
  return uniqueColumns
}
export function onlyUnique(value: any, index: any, self: any) {
  return self.indexOf(value) === index
}
export function getFields(input: any, field: any) {
  var output = []
  for (var i = 0; i < input.length; ++i) output.push(input[i][field])
  return output
}

export {BarChartBuilder}
