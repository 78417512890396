

  export const initialCreateGroupValues = {
    groupName: '',
    description: '',
  }
  export const initialUpdateGroupValues = {
    id: 0,
    groupName: '',
    description: '',
    isActive: false,
    menuItems:[]
  }