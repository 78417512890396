import axios from 'axios'
import {CreateGroupModel, GroupModel, MenuItemModel, UpdateGroupMenuItemRightModel, UpdateGroupModel} from './Models'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_All_GROUPS_URL = `${API_URL}/Groups/getall`
export const GET_All_MENUITEMS_URL = `${API_URL}/MenuItems/GetLookUpList`
export const GROUP_URL = `${API_URL}/Groups`
export const DELETE_GROUP_URL = `${API_URL}/Groups?deleteGroupId=`
export const UPDATE_GROUP_MENU_ITEM_RIGHTS_BY_GROUP_ID_URL = `${API_URL}/GroupMenuItemRights/UpdateGroupMenuItemRightsByGorupId`

export function UpdateGroupMenuItemRights(model: UpdateGroupMenuItemRightModel) {
  return axios.put(UPDATE_GROUP_MENU_ITEM_RIGHTS_BY_GROUP_ID_URL, model)
}
export function GetAllMenuItems() {
  return axios.get<MenuItemModel[]>(GET_All_MENUITEMS_URL)
}
export function GetAllGroups() {
  return axios.get<GroupModel[]>(GET_All_GROUPS_URL)
}
export function CreateGroup(group: CreateGroupModel) {
  return axios.post(GROUP_URL, group)
}
export function UpdateGroup(group: UpdateGroupModel) {
  return axios.put(GROUP_URL, group)
}
export function DeleteGroup(customerId: number) {
  return axios.delete(DELETE_GROUP_URL + customerId)
}
