


export function GetUniqueColumnsOfDataByColumName(columnName, data) {
    //Veri parse edildi
    var convertedData = JSON.parse(data.toLocaleLowerCase())
    //Girilen column name in tüm tabloda içerdiği kayıtlar çekiliyor
    var valueArray = getFields(
        convertedData,
        columnName.toLocaleLowerCase().trim()
    ).filter((x) => x != null)
    //Girilen column name'in içerdiği veriler distinct mantığıyla tekrar etmeyecek bir şekilde listeleniyor ver geri dönülüyor
    var uniqueColumns = valueArray.filter(onlyUnique)
    return uniqueColumns;
}

export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
}
export function getFields(input, field) {
    var output = []
    for (var i = 0; i < input.length; ++i) output.push(input[i][field])
    return output
}

export function GetSeriesFromData(rawData, xAxisColumnName, dataColumnName,countableFieldName) {
    var raw = JSON.parse(rawData.toLocaleLowerCase()),
        nameIndices = Object.create(null),
        dataHash = Object.create(null),
        data = { labels: [], datasets: [] };

    raw.forEach(function (o) {
        if (!(o[xAxisColumnName.toLocaleLowerCase()] in nameIndices)) {
            nameIndices[o[xAxisColumnName.toLocaleLowerCase()]] = data.labels.push(o[xAxisColumnName.toLocaleLowerCase()]) - 1;
            data.datasets.forEach(function (a) { a.data.push(0); });
        }
        if (!dataHash[o[dataColumnName.toLocaleLowerCase()]]) {
            dataHash[o[dataColumnName.toLocaleLowerCase()]] = { label: o[dataColumnName.toLocaleLowerCase()],  data: data.labels.map(function () { return 0; }) };
            data.datasets.push(dataHash[o[dataColumnName.toLocaleLowerCase()]]);
        }
        dataHash[o[dataColumnName.toLocaleLowerCase()]].data[nameIndices[o[xAxisColumnName.toLocaleLowerCase()]]] = o[countableFieldName];

    });
    return data;
}
export function GetTimeLineOption (titleText, dataColumnsArray, dataColumnValues,timeLineValueArray) {
     
    let dataValues = [];
    let timeLineValues = timeLineValueArray;

    Object.entries(dataColumnsArray[0][0]).forEach(entry => {
         
      entry[1].forEach(e => {
        dataValues = [...new Set([...dataValues, e.name])];
      });
    });
 
    var timeLineSeries = [] ;
    dataColumnValues.forEach(value =>{
         
      timeLineSeries.push(
        { name: value, type: "bar" }
      )
    })


    let options = timeLineValues.map( (timeLineValue ,index)=> {

      let obj = {};
       

      var optionSeries = [];
      dataColumnsArray.forEach( (array) => {
           
        optionSeries.push(
          {
            stack: "group",
            data: array[index][timeLineValue]
          }
        )
      })

      obj["series"] = optionSeries;

      obj["title"] = {
        text: `${timeLineValue}  ${titleText}`
      };

      return obj;
    });

    return {

      baseOption: {
        timeline: {
          autoPlay: true,
          axisType: "category",
          bottom: 20,
          data: timeLineValues,
          height: null,
          inverse: true,
          left: null,
          orient: "vertical",
          playInterval: 1000,
          right: 0,
          top: 20,
          width: 55,
          label: {
            normal: {
              textStyle: {
                color: "#aaa"
              }
            },
            emphasis: {
              textStyle: {
                color: "#333"
              }
            }
          },
          symbol: "none",
          lineStyle: {
            color: "#aaa"
          },
          checkpointStyle: {
            color: "#354EF6",
            borderColor: "transparent",
            borderWidth: 2
          },
          controlStyle: {
            showNextBtn: false,
            showPrevBtn: false,
            normal: {
              color: "#354EF6",
              borderColor: "#354EF6"
            },
            emphasis: {
              color: "#5d71f7",
              borderColor: "#5d71f7"
            }
          }
        },
        color: ["#e91e63", "#354EF6"],
        title: {
            textAlign: "left",
            left: "left"
        },
        tooltip: {  borderWidth: 0, padding: 10 },
        legend: {
            data:[],
            orient: 'horizontal',
            left: "center",
            top: "top"
        },
        calculable: true,

        xAxis: [
          {
            axisLabel: {
              interval: 0,
              rotate: 55,
              textStyle: {
                baseline: "top",
                color: "#333",
                fontSize: 10,
                fontWeight: "bold"
              }
            },
            axisLine: { lineStyle: { color: "#aaa" }, show: true },
            axisTick: { show: false },
            data: dataValues,
            splitLine: { show: false },
            type: "category"
          }
        ],
        yAxis: [
          {
            type: "value"
          }
        ],
        series:timeLineSeries
      },
      options: options
    };

  };
export function GroupArrayOfObjects(list, key) {
    return list.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  