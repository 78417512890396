import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {KTSVG} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {DataGrid, GridColDef, GridApi, GridCellValue} from '@mui/x-data-grid'
import {CreateCustomer, DeleteCustomer, GetAllCustomers, UpdateCustomer} from './ApiOperations'
import {useFormik} from 'formik'
import {CreateCustomerModel, UpdateCustomerModel} from './Models'
import clsx from 'clsx'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {GetIsPermissionGranted} from '../../general/GeneralApiOperations'
import {AgGridColumn, AgGridReact} from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import InputMask from 'react-input-mask'
import { useIntl } from 'react-intl'

const mySwal = withReactContent(Swal)
const initialCreateCustomerValues = {
  title: '',
  adress: '',
  adress2: '',
  phone: '',
  phone2: '',
  email: '',
  email2: '',
  webSite: '',
  customerCodes: '',
}
const initialUpdateCustomerValues = {
  id: 0,
  title: '',
  adress: '',
  adress2: '',
  phone: '',
  phone2: '',
  email: '',
  email2: '',
  webSite: '',
  isActive: true,
  customerCodes: '',
}

const CustomerManagement = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const [isGranted, setisGranted] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [tableRows, setTableRows] = useState<any[]>([])
  const [currentCustomerIsActive, setcurrentCustomerIsActive] = useState(false)
  const [currentCustomer, setCurrentCustomer] = useState<UpdateCustomerModel>(
    initialUpdateCustomerValues
  )
  //#region Grid Methods
  const onGridReady = (params: any) => {
    // var gridApi = params.api
    // gridApi.sizeColumnsToFit()
  }
  const EditCellRenderer = (params: any) => {
    const onClick = (e: any) => {
      e.stopPropagation()

      var customer = params.data
      setCurrentCustomer(customer)
      setcurrentCustomerIsActive(params.data.isActive)
    }

    return (
      <button
        data-bs-toggle='modal'
        data-bs-target='#UpdateCustomerModal'
        className='btn btn-sm btn-info w-100'
        onClick={onClick}
      >
        {!isLoading && <span>{language["PAGES.MANAGEMENT.CUSTOMER.EDITBUTTON"]}</span>}

        {isLoading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            {language["PAGES.MANAGEMENT.CUSTOMER.PLEASEWAIT"]}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    )
  }
  const ReportManagementCellRenderer = (params: any) => {
    const onClick = (e: any) => {
      e.stopPropagation() // don't select this row after clicking

      var customer = params.data
      setCurrentCustomer(customer)
      window.location.href = `/customer-report-management/${customer.id}`
      // return <Redirect to={`/customer-report-management/${customerId}`}  />
    }

    return (
      <button className='btn btn-sm btn-warning w-100' onClick={onClick}>
        {!isLoading && <span>{language["PAGES.MANAGEMENT.CUSTOMER.REPORTMANAGEMENTBUTTON"]}</span>}

        {isLoading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            {language["PAGES.MANAGEMENT.CUSTOMER.PLEASEWAIT"]}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    )
  }
  const DeleteCellRenderer = (params: any) => {
    const onClick = (e: any) => {
      e.stopPropagation()
      setLoading(true)

      var customer = params.data
      if (user.customerId == customer.id) {
        mySwal
          .fire(language["PAGES.MANAGEMENT.CUSTOMER.INFORMATION"].toString(), language["PAGES.MANAGEMENT.CUSTOMER.DELETEERRORMESSAGE"].toString(), 'info')
          .then(() => {
            setLoading(false)
          })
      } else if (user.customerId !== customer.id) {
        mySwal
          .fire({
            title: language["PAGES.MANAGEMENT.CUSTOMER.CONFIRM"].toString(),
            icon: 'info',
            text: `${customer.title}  ${language["PAGES.MANAGEMENT.CUSTOMER.DELETEINFO"]}`,
            showDenyButton: true,
            confirmButtonText: language["PAGES.MANAGEMENT.CUSTOMER.DELETEBUTTON"].toString(),
            denyButtonText: language["PAGES.MANAGEMENT.CUSTOMER.CANCELBUTTON"].toString(),
          })
          .then((result) => {
            if (result.isConfirmed) {
              DeleteCustomer(customer.id)
                .then((response) => {
                  mySwal
                    .fire(language["PAGES.MANAGEMENT.CUSTOMER.DELETESUCCESS"].toString(), language["PAGES.MANAGEMENT.CUSTOMER.DELETESUCCESSMESSAGE"].toString(), 'success')
                    .then(() => {
                      GetData()
                      setLoading(false)
                    })
                })
                .catch(() => {
                  mySwal.fire(language["PAGES.MANAGEMENT.CUSTOMER.DELETEERROR"].toString(), language["PAGES.MANAGEMENT.CUSTOMER.DELETEERRORMESSAGESECOND"].toString(), 'error').then(() => {
                    setLoading(false)
                  })
                })
            }
            setLoading(false)
          })
      } else {
        mySwal.fire(language["PAGES.MANAGEMENT.CUSTOMER.INFORMATION"].toString(), language["PAGES.MANAGEMENT.CUSTOMER.DELETEERRORMESSAGETHIRD"].toString(), 'info').then(() => {
          setLoading(false)
        })
      }
    }

    return (
      <button className='btn btn-sm btn-danger w-100' onClick={onClick}>
        {!isLoading && <span className='indicator-label'>{language["PAGES.MANAGEMENT.CUSTOMER.DELETEBUTTON"]}</span>}
        {isLoading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            {language["PAGES.MANAGEMENT.CUSTOMER.PLEASEWAIT"]}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    )
  }
  const IsActiveCellRenderer = (params: any) => {
    var isActive = params.data.isActive
    return (
      <div className='mb-0'>
        <div className='form-check form-check-custom form-check-solid'>
          {isActive ? (
            <input className='form-check-input mt-2' type='checkbox' disabled value='' checked />
          ) : (
            <input className='form-check-input mt-2' type='checkbox' disabled value='' />
          )}
        </div>
      </div>
    )
  }
  //#endregion

  const GetData = () => {
    GetAllCustomers()
      .then((response) => {
        var customers = response.data
        var customerList: any[] = []

        customers.forEach((customer) => {
          var xCustomer = {
            id: customer.id,
            title: customer.title,
            adress: customer.adress,
            adress2: customer.adress2,
            phone: customer.phone,
            phone2: customer.phone2,
            email: customer.email,
            email2: customer.email2,
            webSite: customer.webSite,
            customerCodes: customer.customerCodes,
            createdByStr: customer.createdByStr,
            createdDateStr: customer.createdDateStr,
            userCount: customer.userCount,
            isActive: customer.isActive,
          }
          customerList.push(xCustomer)
        })
        setTableRows(customerList)
      })
      .catch((error) => {
        alert(language["PAGES.MANAGEMENT.CUSTOMER.EXCEPTIONMESSAGE"].toString() + error.response)
      })
  }
  const language = useIntl().messages

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      GetIsPermissionGranted('/customer-management').then((response) => {
        var isGranted = response.data

        setisGranted(isGranted)

        if (isGranted === false) {
          window.location.href = '/error/404'
        }
      })
      GetData()
    }
    return () => {
      isMounted = false
    }
  }, [])
  const customerySchema = Yup.object().shape({
    title: Yup.string()
      .min(5, language["PAGES.MANAGEMENT.CUSTOMER.TITLEVALIDATIONCHARACTERMESSAGE"].toString())
      .typeError(language["PAGES.MANAGEMENT.CUSTOMER.TITLEVALIDATIONMESSAGE"].toString())
      .required(language["PAGES.MANAGEMENT.CUSTOMER.TITLEVALIDATIONMESSAGE"].toString()),
    adress: Yup.string()
      .min(5, language["PAGES.MANAGEMENT.CUSTOMER.ADDRESSVALIDATIONCHARACTERMESSAGE"].toString())
      .typeError(language["PAGES.MANAGEMENT.CUSTOMER.ADDRESSVALIDATIONMESSAGE"].toString())
      .required(language["PAGES.MANAGEMENT.CUSTOMER.ADDRESSVALIDATIONMESSAGE"].toString()),
    adress2: Yup.string().min(5, language["PAGES.MANAGEMENT.CUSTOMER.ADDRESSVALIDATIONCHARACTERMESSAGE"].toString()).typeError(''),
    phone: Yup.string()
      .min(11, language["PAGES.MANAGEMENT.CUSTOMER.PHONEVALIDATIONCHARACTERMESSAGE"].toString())
      .typeError(language["PAGES.MANAGEMENT.CUSTOMER.PHONEVALIDATIONMESSAGE"].toString())
      .required(language["PAGES.MANAGEMENT.CUSTOMER.PHONEVALIDATIONMESSAGE"].toString()),
    phone2: Yup.string().min(11, language["PAGES.MANAGEMENT.CUSTOMER.PHONEVALIDATIONCHARACTERMESSAGE"].toString()).typeError(''),
    email: Yup.string()
      .email(language["PAGES.MANAGEMENT.CUSTOMER.EMAILVALIDATIONFORMATMESSAGE"].toString())
      .min(5, language["PAGES.MANAGEMENT.CUSTOMER.EMAILVALIDATIONCHARACTERMESSAGE"].toString())
      .typeError(language["PAGES.MANAGEMENT.CUSTOMER.EMAILVALIDATIONMESSAGE"].toString())
      .required(language["PAGES.MANAGEMENT.CUSTOMER.EMAILVALIDATIONMESSAGE"].toString()),
    email2: Yup.string()
      .email(language["PAGES.MANAGEMENT.CUSTOMER.EMAILVALIDATIONFORMATMESSAGE"].toString())
      .typeError('')
      .min(5, language["PAGES.MANAGEMENT.CUSTOMER.EMAILVALIDATIONCHARACTERMESSAGE"].toString()),
    webSite: Yup.string()
      .min(5, language["PAGES.MANAGEMENT.CUSTOMER.WEBSITEVALIDATIONCHARACTERMESSAGE"].toString())
      .typeError(language["PAGES.MANAGEMENT.CUSTOMER.WEBSITEVALIDATIONFORMATMESSAGE"].toString()),
    customerCodes: Yup.string()
      .typeError(language["PAGES.MANAGEMENT.CUSTOMER.CUSTOMERCODESVALIDATIONMESSAGE"].toString())
      .min(1, language["PAGES.MANAGEMENT.CUSTOMER.CUSTOMERCODESVALIDATIONCHARACTERMESSAGE"].toString())
      .required(language["PAGES.MANAGEMENT.CUSTOMER.CUSTOMERCODESVALIDATIONMESSAGE"].toString()),
    isActive: Yup.boolean(),
  })
  const CreateCustomerFormik = useFormik({
    initialValues: initialCreateCustomerValues,
    validationSchema: customerySchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setTimeout(() => {
        CreateCustomer(values as CreateCustomerModel)
          .then((reponse) => {
            mySwal.fire(language["PAGES.MANAGEMENT.CUSTOMER.CREATESUCCESS"].toString(), language["PAGES.MANAGEMENT.CUSTOMER.CREATESUCCESSMESSAGE"].toString(), 'success').then(() => {
              GetData()
              resetForm()
              setLoading(false)
              window.location.reload()
            })
          })
          .catch((error) => {
            console.log(error.response.data)
            setLoading(false)
            setSubmitting(false)
            mySwal.fire(
              language["PAGES.MANAGEMENT.CUSTOMER.CREATEERROR"].toString(),
              language["PAGES.MANAGEMENT.CUSTOMER.CREATEERRORMESSAGE"].toString(),
              'error'
            )
            setStatus(error.message)
          })
      }, 1000)
    },
  })
  const UpdateCustomerFormik = useFormik({
    enableReinitialize: true,
    initialValues: currentCustomer,
    validationSchema: customerySchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      values.isActive = currentCustomerIsActive

      setLoading(true)
      setTimeout(() => {
        UpdateCustomer(values as UpdateCustomerModel)
          .then((reponse) => {
            mySwal.fire(language["PAGES.MANAGEMENT.CUSTOMER.UPDATESUCCESS"].toString(), language["PAGES.MANAGEMENT.CUSTOMER.UPDATESUCCESSMESSAGE"].toString(), 'success').then(() => {
              GetData()
              resetForm()
              setLoading(false)
              window.location.reload()
            })
          })
          .catch((error) => {
            console.log(error.response.data)
            setLoading(false)
            setSubmitting(false)
            mySwal.fire(
              language["PAGES.MANAGEMENT.CUSTOMER.UPDATEERROR"].toString(),
              language["PAGES.MANAGEMENT.CUSTOMER.UPDATEERRORMESSAGE"].toString(),
              'error'
            )
            setStatus(error.message)
          })
      }, 1000)
    },
  })


  return (
    <>
      {isGranted ? (
        <>
          <>
            <div className='row alert alert-primary d-flex align-items-center pb-10 text-center'>
              <div className='col-md-10'>
                <h5 className='mb-1'> {language["PAGES.MANAGEMENT.CUSTOMER.PAGETITLE"]}</h5>
                <span>
                  {language["PAGES.MANAGEMENT.CUSTOMER.PAGEDESCRIPTION"]}
                </span>
              </div>
              <div className='col-md-2 '>
                <button
                  data-bs-toggle='modal'
                  data-bs-target='#NewCustomerModal'
                  className='btn btn-md btn-primary mt-5 float-right w-100'
                >
                  <i className='fa fa-plus' aria-hidden='true'></i>
                  {language["PAGES.MANAGEMENT.CUSTOMER.NEWCUSTOMERBUTTON"]}
                </button>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card card-custom  card-stretch shadow'>
                  <div className='card-body'>
                    <div className='ag-theme-alpine' style={{height: 600, width: '100%'}}>
                      <AgGridReact
                        frameworkComponents={{
                          reportManagementCellRenderer: ReportManagementCellRenderer,
                          isActiveCellRenderer: IsActiveCellRenderer,
                          editCellRenderer: EditCellRenderer,
                          deleteCellRenderer: DeleteCellRenderer,
                        }}
                        defaultColDef={{
                          sortable: true,
                          flex: 1,

                          filter: true,
                          resizable: true,
                        }}
                        onGridReady={onGridReady}
                        rowData={tableRows}
                      >
                        <AgGridColumn field='id' hide={true} />
                        <AgGridColumn field='title' headerName={language["PAGES.MANAGEMENT.CUSTOMER.GRID.TITLE"].toString()} />
                        <AgGridColumn field='adress' headerName={language["PAGES.MANAGEMENT.CUSTOMER.GRID.ADDRESS"].toString()} />
                        <AgGridColumn field='adress2' headerName={language["PAGES.MANAGEMENT.CUSTOMER.GRID.ADDRESSSECOND"].toString()} />
                        <AgGridColumn field='phone' headerName={language["PAGES.MANAGEMENT.CUSTOMER.GRID.PHONE"].toString()} />
                        <AgGridColumn field='phone2' headerName={language["PAGES.MANAGEMENT.CUSTOMER.GRID.PHONESECOND"].toString()}/>
                        <AgGridColumn field='email' headerName={language["PAGES.MANAGEMENT.CUSTOMER.GRID.EMAIL"].toString()} />
                        <AgGridColumn field='webSite' headerName={language["PAGES.MANAGEMENT.CUSTOMER.GRID.WEBSITE"].toString()} />
                        <AgGridColumn field='createdByStr' headerName={language["PAGES.MANAGEMENT.CUSTOMER.GRID.CREATEDBY"].toString()} />
                        <AgGridColumn field='createdDateStr' headerName={language["PAGES.MANAGEMENT.CUSTOMER.GRID.CREATEDDATE"].toString()} />
                        <AgGridColumn
                          field='isActive'
                          headerName={language["PAGES.MANAGEMENT.CUSTOMER.GRID.ISACTIVE"].toString()}
                          width={100}
                          cellRenderer='isActiveCellRenderer'
                        />
                        {user.email === 'info@restyazilim.com' ? (
                          <AgGridColumn
                            field='reportManagement'
                            headerName={language["PAGES.MANAGEMENT.CUSTOMER.GRID.REPORTMANAGEMENT"].toString()}
                            width={150}
                            pinned='right'
                            cellRenderer='reportManagementCellRenderer'
                          />
                        ) : (
                          <></>
                        )}

                        <AgGridColumn
                          field='düzenle'
                          headerName={language["PAGES.MANAGEMENT.CUSTOMER.GRID.EDIT"].toString()}
                          pinned='right'
                          width={125}
                          cellRenderer='editCellRenderer'
                        />
                        <AgGridColumn
                          field='sil'
                          headerName={language["PAGES.MANAGEMENT.CUSTOMER.GRID.DELETE"].toString()}
                          pinned='right'
                          width={100}
                          cellRenderer='deleteCellRenderer'
                        />
                      </AgGridReact>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='modal fade' tabIndex={-1} id='UpdateCustomerModal'>
              <div className='modal-dialog   modal-xl'>
                <div className='modal-content'>
                  <form className='form w-100' onSubmit={UpdateCustomerFormik.handleSubmit}>
                    <div className='modal-header'>
                      <h5 className='modal-title'>{language["PAGES.MANAGEMENT.CUSTOMER.UPDATECUSTOMERTITLE"]}</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </div>
                    <div className='modal-body text-center'>
                      <div data-scroll='true' data-height='300'>
                        {/* begin::Form group */}
                        <div className='fv-row mb-10'>
                          <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.CUSTOMER.TITLE"]}</label>
                          <input
                            placeholder={language["PAGES.MANAGEMENT.CUSTOMER.TITLEPLACEHOLDER"].toString()}
                            {...UpdateCustomerFormik.getFieldProps('title')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid text-center',
                              {
                                'is-invalid':
                                  UpdateCustomerFormik.touched.title &&
                                  UpdateCustomerFormik.errors.title,
                              },
                              {
                                'is-valid':
                                  UpdateCustomerFormik.touched.title &&
                                  !UpdateCustomerFormik.errors.title,
                              }
                            )}
                            type='text'
                            name='title'
                            autoComplete='off'
                          />
                          {UpdateCustomerFormik.touched.title && UpdateCustomerFormik.errors.title && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {UpdateCustomerFormik.errors.title}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className='row mb-10'>
                          <div className='col-md-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.CUSTOMER.PHONE"]}</label>
                            <InputMask
                              placeholder={language["PAGES.MANAGEMENT.CUSTOMER.PHONEPLACEHOLDER"].toString()}
                              {...UpdateCustomerFormik.getFieldProps('phone')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid text-center',
                                {
                                  'is-invalid':
                                    UpdateCustomerFormik.touched.phone &&
                                    UpdateCustomerFormik.errors.phone,
                                },
                                {
                                  'is-valid':
                                    UpdateCustomerFormik.touched.phone &&
                                    !UpdateCustomerFormik.errors.phone,
                                }
                              )}
                              mask='0 (999) 999 99 99'
                              type='tel'
                              name='phone'
                              autoComplete='off'
                            ></InputMask>
                            {UpdateCustomerFormik.touched.phone &&
                              UpdateCustomerFormik.errors.phone && (
                                <div className='fv-plugins-message-container'>
                                  <span role='alert' className='text-danger'>
                                    {UpdateCustomerFormik.errors.phone}
                                  </span>
                                </div>
                              )}
                          </div>
                          <div className='col-md-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>
                              {language["PAGES.MANAGEMENT.CUSTOMER.PHONESECOND"]}
                            </label>
                            <InputMask
                              placeholder={language["PAGES.MANAGEMENT.CUSTOMER.OPTIONALPLACEHOLDER"].toString()}
                              {...UpdateCustomerFormik.getFieldProps('phone2')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid text-center',
                                {
                                  'is-invalid':
                                    UpdateCustomerFormik.touched.phone2 &&
                                    UpdateCustomerFormik.errors.phone2,
                                },
                                {
                                  'is-valid':
                                    UpdateCustomerFormik.touched.phone2 &&
                                    !UpdateCustomerFormik.errors.phone2,
                                }
                              )}
                              mask='0 (999) 999 99 99'
                              type='tel'
                              name='phone2'
                              autoComplete='off'
                            ></InputMask>

                            {UpdateCustomerFormik.touched.phone2 &&
                              UpdateCustomerFormik.errors.phone2 && (
                                <div className='fv-plugins-message-container'>
                                  <span role='alert' className='text-danger'>
                                    {UpdateCustomerFormik.errors.phone2}
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>

                        <div className='row mb-10'>
                          <div className='col-md-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.CUSTOMER.EMAIL"]}</label>

                            <input
                              placeholder={language["PAGES.MANAGEMENT.CUSTOMER.EMAILPLACEHOLDER"].toString()}
                              {...UpdateCustomerFormik.getFieldProps('email')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid text-center',
                                {
                                  'is-invalid':
                                    UpdateCustomerFormik.touched.email &&
                                    UpdateCustomerFormik.errors.email,
                                },
                                {
                                  'is-valid':
                                    UpdateCustomerFormik.touched.email &&
                                    !UpdateCustomerFormik.errors.email,
                                }
                              )}
                              type='email'
                              name='email'
                              autoComplete='off'
                            />
                            {UpdateCustomerFormik.touched.email &&
                              UpdateCustomerFormik.errors.email && (
                                <div className='fv-plugins-message-container'>
                                  <span role='alert' className='text-danger'>
                                    {UpdateCustomerFormik.errors.email}
                                  </span>
                                </div>
                              )}
                          </div>
                          <div className='col-md-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.CUSTOMER.EMAILSECOND"]}</label>
                            <input
                              placeholder={language["PAGES.MANAGEMENT.CUSTOMER.OPTIONALPLACEHOLDER"].toString()}
                              {...UpdateCustomerFormik.getFieldProps('email2')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid text-center',
                                {
                                  'is-invalid':
                                    UpdateCustomerFormik.touched.email2 &&
                                    UpdateCustomerFormik.errors.email2,
                                },
                                {
                                  'is-valid':
                                    UpdateCustomerFormik.touched.email2 &&
                                    !UpdateCustomerFormik.errors.email2,
                                }
                              )}
                              type='email'
                              name='email2'
                              autoComplete='off'
                            />
                            {UpdateCustomerFormik.touched.email2 &&
                              UpdateCustomerFormik.errors.email2 && (
                                <div className='fv-plugins-message-container'>
                                  <span role='alert' className='text-danger'>
                                    {UpdateCustomerFormik.errors.email2}
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className='row mb-10'>
                          <div className='col-md-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.CUSTOMER.ADRESS"]}</label>
                            <textarea
                              placeholder={language["PAGES.MANAGEMENT.CUSTOMER.ADDRESSPLACEHOLDER"].toString()}
                              {...UpdateCustomerFormik.getFieldProps('adress')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid text-center',
                                {
                                  'is-invalid':
                                    UpdateCustomerFormik.touched.adress &&
                                    UpdateCustomerFormik.errors.adress,
                                },
                                {
                                  'is-valid':
                                    UpdateCustomerFormik.touched.adress &&
                                    !UpdateCustomerFormik.errors.adress,
                                }
                              )}
                              name='adress'
                              autoComplete='off'
                            />
                            {UpdateCustomerFormik.touched.adress &&
                              UpdateCustomerFormik.errors.adress && (
                                <div className='fv-plugins-message-container'>
                                  <span role='alert' className='text-danger'>
                                    {UpdateCustomerFormik.errors.adress}
                                  </span>
                                </div>
                              )}
                          </div>
                          <div className='col-md-6'>
                            <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.CUSTOMER.ADDRESSSECOND"]}</label>
                            <textarea
                              placeholder={language["PAGES.MANAGEMENT.CUSTOMER.OPTIONALPLACEHOLDER"].toString()}
                              {...UpdateCustomerFormik.getFieldProps('adress2')}
                              className={clsx(
                                'form-control form-control-lg form-control-solid text-center',
                                {
                                  'is-invalid':
                                    UpdateCustomerFormik.touched.adress2 &&
                                    UpdateCustomerFormik.errors.adress2,
                                },
                                {
                                  'is-valid':
                                    UpdateCustomerFormik.touched.adress2 &&
                                    !UpdateCustomerFormik.errors.adress2,
                                }
                              )}
                              name='adress2'
                              autoComplete='off'
                            />
                            {UpdateCustomerFormik.touched.adress2 &&
                              UpdateCustomerFormik.errors.adress2 && (
                                <div className='fv-plugins-message-container'>
                                  <span role='alert' className='text-danger'>
                                    {UpdateCustomerFormik.errors.adress2}
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className='fv-row mb-10'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            {language["PAGES.MANAGEMENT.CUSTOMER.CUSTOMERCODES"]}
                          </label>
                          <input
                            placeholder={language["PAGES.MANAGEMENT.CUSTOMER.CUSTOMERCODESPLACEHOLDER"].toString()}
                            {...UpdateCustomerFormik.getFieldProps('customerCodes')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid text-center',
                              {
                                'is-invalid':
                                  UpdateCustomerFormik.touched.customerCodes &&
                                  UpdateCustomerFormik.errors.customerCodes,
                              },
                              {
                                'is-valid':
                                  UpdateCustomerFormik.touched.customerCodes &&
                                  !UpdateCustomerFormik.errors.customerCodes,
                              }
                            )}
                            type='text'
                            name='customerCodes'
                            autoComplete='off'
                          />
                          {UpdateCustomerFormik.touched.customerCodes &&
                            UpdateCustomerFormik.errors.customerCodes && (
                              <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>
                                  {UpdateCustomerFormik.errors.customerCodes}
                                </span>
                              </div>
                            )}
                        </div>
                        <div className='fv-row mb-10'>
                          <label className='form-label fs-6 fw-bolder text-dark'>
                            {language["PAGES.MANAGEMENT.CUSTOMER.WEBSITE"]}
                          </label>
                          <input
                            placeholder={language["PAGES.MANAGEMENT.CUSTOMER.OPTIONALPLACEHOLDER"].toString()}
                            {...UpdateCustomerFormik.getFieldProps('webSite')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid text-center',
                              {
                                'is-invalid':
                                  UpdateCustomerFormik.touched.webSite &&
                                  UpdateCustomerFormik.errors.webSite,
                              },
                              {
                                'is-valid':
                                  UpdateCustomerFormik.touched.webSite &&
                                  !UpdateCustomerFormik.errors.webSite,
                              }
                            )}
                            type='text'
                            name='webSite'
                            autoComplete='off'
                          />
                          {UpdateCustomerFormik.touched.webSite &&
                            UpdateCustomerFormik.errors.webSite && (
                              <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>
                                  {UpdateCustomerFormik.errors.webSite}
                                </span>
                              </div>
                            )}
                        </div>
                        <div className='row mb-10'>
                          <div className='col-sm-2  p-0'>
                            <p className='fs-6 fw-bolder ml-5'>{language["PAGES.MANAGEMENT.CUSTOMER.ISACITVE"]}</p>
                          </div>
                          <div className='col-sm-1 p-0'>
                            <div className='form-check form-check-custom form-check-solid '>
                              {
                                <input
                                  placeholder={language["PAGES.MANAGEMENT.CUSTOMER.OPTIONALPLACEHOLDER"].toString()}
                                  {...UpdateCustomerFormik.getFieldProps('isActive')}
                                  className='form-check-input'
                                  type='checkbox'
                                  name='isActive'
                                  onChange={(e) => {
                                    setcurrentCustomerIsActive(e.target.checked)
                                  }}
                                  checked={currentCustomerIsActive}
                                />
                              }
                            </div>
                          </div>
                        </div>
                        {/* end::Form group */}
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                        {language["PAGES.MANAGEMENT.CUSTOMER.CANCELBUTTON"]}
                      </button>
                      <button type='submit' className='btn btn-primary'>
                        {!isLoading && <span>{language["PAGES.MANAGEMENT.CUSTOMER.UPDATEBUTTON"]}</span>}
                        {isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            {language["PAGES.MANAGEMENT.CUSTOMER.PLEASEWAIT"]}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='modal fade' tabIndex={-1} id='NewCustomerModal'>
              <div className='modal-dialog modal-xl'>
                <div className='modal-content'>
                  <form
                    className='form w-100'
                    onSubmit={CreateCustomerFormik.handleSubmit}
                    noValidate
                  >
                    <div className='modal-header'>
                      <h5 className='modal-title'>{language["PAGES.MANAGEMENT.CUSTOMER.NEWCUSTOMERTITLE"]}</h5>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </div>
                    <div className='modal-body text-center'>
                      {/* begin::Form group */}
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.CUSTOMER.TITLE"]}</label>
                        <input
                          placeholder={language["PAGES.MANAGEMENT.CUSTOMER.TITLEPLACEHOLDER"].toString()}
                          {...CreateCustomerFormik.getFieldProps('title')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid text-center',
                            {
                              'is-invalid':
                                CreateCustomerFormik.touched.title &&
                                CreateCustomerFormik.errors.title,
                            },
                            {
                              'is-valid':
                                CreateCustomerFormik.touched.title &&
                                !CreateCustomerFormik.errors.title,
                            }
                          )}
                          type='text'
                          name='title'
                          autoComplete='off'
                        />
                        {CreateCustomerFormik.touched.title && CreateCustomerFormik.errors.title && (
                          <div className='fv-plugins-message-container'>
                            <span role='alert' className='text-danger'>
                              {CreateCustomerFormik.errors.title}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className='row mb-10'>
                        <div className='col-md-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.CUSTOMER.PHONE"]}</label>
                          <InputMask
                            placeholder={language["PAGES.MANAGEMENT.CUSTOMER.PHONEPLACEHOLDER"].toString()}
                            {...CreateCustomerFormik.getFieldProps('phone')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid text-center',
                              {
                                'is-invalid':
                                  CreateCustomerFormik.touched.phone &&
                                  CreateCustomerFormik.errors.phone,
                              },
                              {
                                'is-valid':
                                  CreateCustomerFormik.touched.phone &&
                                  !CreateCustomerFormik.errors.phone,
                              }
                            )}
                            mask='0 (999) 999 99 99'
                            type='tel'
                            name='phone'
                            autoComplete='off'
                          ></InputMask>
                          {CreateCustomerFormik.touched.phone && CreateCustomerFormik.errors.phone && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {CreateCustomerFormik.errors.phone}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className='col-md-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.CUSTOMER.PHONESECOND"]}</label>
                          <InputMask
                            placeholder={language["PAGES.MANAGEMENT.CUSTOMER.OPTIONALPLACEHOLDER"].toString()}
                            {...CreateCustomerFormik.getFieldProps('phone2')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid text-center',
                              {
                                'is-invalid':
                                  CreateCustomerFormik.touched.phone2 &&
                                  CreateCustomerFormik.errors.phone2,
                              },
                              {
                                'is-valid':
                                  CreateCustomerFormik.touched.phone2 &&
                                  !CreateCustomerFormik.errors.phone2,
                              }
                            )}
                            mask='0 (999) 999 99 99'
                            type='tel'
                            name='phone2'
                            autoComplete='off'
                          ></InputMask>

                          {CreateCustomerFormik.touched.phone2 &&
                            CreateCustomerFormik.errors.phone2 && (
                              <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>
                                  {CreateCustomerFormik.errors.phone2}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>

                      <div className='row mb-10'>
                        <div className='col-md-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.CUSTOMER.EMAIL"]}</label>
                          <input
                            placeholder={language["PAGES.MANAGEMENT.CUSTOMER.EMAILPLACEHOLDER"].toString()}
                            {...CreateCustomerFormik.getFieldProps('email')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid text-center',
                              {
                                'is-invalid':
                                  CreateCustomerFormik.touched.email &&
                                  CreateCustomerFormik.errors.email,
                              },
                              {
                                'is-valid':
                                  CreateCustomerFormik.touched.email &&
                                  !CreateCustomerFormik.errors.email,
                              }
                            )}
                            type='email'
                            name='email'
                            autoComplete='off'
                          />
                          {CreateCustomerFormik.touched.email && CreateCustomerFormik.errors.email && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {CreateCustomerFormik.errors.email}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className='col-md-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.CUSTOMER.EMAILSECOND"]}</label>
                          <input
                            placeholder={language["PAGES.MANAGEMENT.CUSTOMER.OPTIONALPLACEHOLDER"].toString()}
                            {...CreateCustomerFormik.getFieldProps('email2')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid text-center',
                              {
                                'is-invalid':
                                  CreateCustomerFormik.touched.email2 &&
                                  CreateCustomerFormik.errors.email2,
                              },
                              {
                                'is-valid':
                                  CreateCustomerFormik.touched.email2 &&
                                  !CreateCustomerFormik.errors.email2,
                              }
                            )}
                            type='email'
                            name='email2'
                            autoComplete='off'
                          />
                          {CreateCustomerFormik.touched.email2 &&
                            CreateCustomerFormik.errors.email2 && (
                              <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>
                                  {CreateCustomerFormik.errors.email2}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className='row mb-10'>
                        <div className='col-md-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.CUSTOMER.ADRESS"]}</label>
                          <textarea
                            placeholder={language["PAGES.MANAGEMENT.CUSTOMER.ADDRESSPLACEHOLDER"].toString()}
                            {...CreateCustomerFormik.getFieldProps('adress')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid text-center',
                              {
                                'is-invalid':
                                  CreateCustomerFormik.touched.adress &&
                                  CreateCustomerFormik.errors.adress,
                              },
                              {
                                'is-valid':
                                  CreateCustomerFormik.touched.adress &&
                                  !CreateCustomerFormik.errors.adress,
                              }
                            )}
                            name='adress'
                            autoComplete='off'
                          />
                          {CreateCustomerFormik.touched.adress &&
                            CreateCustomerFormik.errors.adress && (
                              <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>
                                  {CreateCustomerFormik.errors.adress}
                                </span>
                              </div>
                            )}
                        </div>
                        <div className='col-md-6'>
                          <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.CUSTOMER.ADDRESSSECOND"]}</label>
                          <textarea
                            placeholder={language["PAGES.MANAGEMENT.CUSTOMER.OPTIONALPLACEHOLDER"].toString()}
                            {...CreateCustomerFormik.getFieldProps('adress2')}
                            className={clsx(
                              'form-control form-control-lg form-control-solid text-center',
                              {
                                'is-invalid':
                                  CreateCustomerFormik.touched.adress2 &&
                                  CreateCustomerFormik.errors.adress2,
                              },
                              {
                                'is-valid':
                                  CreateCustomerFormik.touched.adress2 &&
                                  !CreateCustomerFormik.errors.adress2,
                              }
                            )}
                            name='adress2'
                            autoComplete='off'
                          />
                          {CreateCustomerFormik.touched.adress2 &&
                            CreateCustomerFormik.errors.adress2 && (
                              <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>
                                  {CreateCustomerFormik.errors.adress2}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>
                          {language["PAGES.MANAGEMENT.CUSTOMER.CUSTOMERCODES"]}
                        </label>
                        <input
                          placeholder={language["PAGES.MANAGEMENT.CUSTOMER.CUSTOMERCODESPLACEHOLDER"].toString()}
                          {...CreateCustomerFormik.getFieldProps('customerCodes')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid text-center',
                            {
                              'is-invalid':
                                CreateCustomerFormik.touched.customerCodes &&
                                CreateCustomerFormik.errors.customerCodes,
                            },
                            {
                              'is-valid':
                                CreateCustomerFormik.touched.customerCodes &&
                                !CreateCustomerFormik.errors.customerCodes,
                            }
                          )}
                          type='text'
                          name='customerCodes'
                          autoComplete='off'
                        />
                        {CreateCustomerFormik.touched.customerCodes &&
                          CreateCustomerFormik.errors.customerCodes && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {CreateCustomerFormik.errors.customerCodes}
                              </span>
                            </div>
                          )}
                      </div>
                      <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>{language["PAGES.MANAGEMENT.CUSTOMER.WEBSITE"]}</label>
                        <input
                          placeholder={language["PAGES.MANAGEMENT.CUSTOMER.OPTIONALPLACEHOLDER"].toString()}
                          {...CreateCustomerFormik.getFieldProps('webSite')}
                          className={clsx(
                            'form-control form-control-lg form-control-solid text-center',
                            {
                              'is-invalid':
                                CreateCustomerFormik.touched.webSite &&
                                CreateCustomerFormik.errors.webSite,
                            },
                            {
                              'is-valid':
                                CreateCustomerFormik.touched.webSite &&
                                !CreateCustomerFormik.errors.webSite,
                            }
                          )}
                          type='text'
                          name='webSite'
                          autoComplete='off'
                        />
                        {CreateCustomerFormik.touched.webSite &&
                          CreateCustomerFormik.errors.webSite && (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className='text-danger'>
                                {CreateCustomerFormik.errors.webSite}
                              </span>
                            </div>
                          )}
                      </div>

                      {/* end::Form group */}
                    </div>
                    <div className='modal-footer'>
                      <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                        {language["PAGES.MANAGEMENT.CUSTOMER.CANCELBUTTON"]}
                      </button>
                      <button type='submit' className='btn btn-primary'>
                        {!isLoading && <span>{language["PAGES.MANAGEMENT.CUSTOMER.CREATEBUTTON"]}</span>}
                        {isLoading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            {language["PAGES.MANAGEMENT.CUSTOMER.PLEASEWAIT"]}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default CustomerManagement
