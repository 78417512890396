import React, {useEffect, useState} from 'react'
import {Redirect, useParams} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {GetReportPageDataByScretKey} from './ApiOperations'
import {ReportPageData} from './Models'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { useIntl } from 'react-intl'
const mySwal = withReactContent(Swal)

const ZReportPage = () => {
  const [reportPageData, setReportpageData] = useState<ReportPageData>()

  const {secretKey} = useParams<{secretKey: string}>()

  const GetData = (secretKey: string) => {
    GetReportPageDataByScretKey(secretKey)
      .then((response) => {
        var pageData = response.data
        setReportpageData(pageData)
      })
      .catch((error) => {
        mySwal
          .fire(
            language["PAGES.ZREPORT.REPORTERROR"].toString(),
            language["PAGES.ZREPORT.REPORTERRORMESSAGE"].toString(),
            'error'
          )
          .then(() => {
            window.location.href = '/dashboard'
          })
      })
  }

  const language = useIntl().messages

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      GetData(secretKey)
    }
    return () => {
      isMounted = false
    }
  }, [secretKey])
  return (
    <>
      
          <div className='row p-0 '>
            <div className='col-lg-12 bg-light p-0 '>
              <div
                className='w-100 bg-light p-0  rounded-4'
                style={{
                  border: 'solid !important',
                  borderColor: 'white !important',
                  borderRadius: '50px !important',
                }}
              >
                <iframe
                  className='bg-light border rounded'
                  style={{
                    position: 'absolute',
                    width: '90%',
                    height: '85%',
                    border: 'solid',
                    borderColor: 'white',
                    borderRadius: '50px',
                  }}
                  src={reportPageData?.reportUrl}
                ></iframe>
              </div>
            </div>
          </div>
      
    </>
  )
}

export default ZReportPage
