import * as Yup from 'yup'
export const createReportSchema = Yup.object().shape({
  customerId: Yup.number(),
  zReportId: Yup.number().required('Rapor seçimi zorunludur'),
  reportUrl: Yup.string()
    .typeError('Url zorunludur')
    .min(10, 'Minimum 10 karakter olmalı')
    .required('Url zorunludur'),
  isDashboard: Yup.boolean(),
})
export const updateReportSchema = Yup.object().shape({
  zReportId: Yup.number().required('Rapor seçimi zorunludur'),
  reportUrl: Yup.string()
    .typeError('Url zorunludur')
    .min(10, 'Minimum 10 karakter olmalı')
    .required('Url zorunludur'),
  isDashboard: Yup.boolean(),
})
export const initialCreateReportValues = {
  customerId: 0,
  zReportId: 0,
  reportUrl: '',
  isDashboard: false,
}
export const initialUpdateReportValues = {
  id: 0,
  customerId: 0,
  customerName:'',
  zReportId: 0,
  reportUrl: '',
  reportName: '',
  isDashboard: false,
}
