import axios from 'axios'
import {CreateUserModel, CustomerModel, GroupModel, ListUserModel, MenuItemModel, UpdateUserGroupsModel, UpdateUserMenuItemRightModel, UpdateUserModel, UserTypeModel} from './Models'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_ALL_USERS_URL = `${API_URL}/Users/getall`
export const GET_ALL_USER_TYPES_URL = `${API_URL}/UserTypes/getall`
export const GET_ALL_CUSTOMERS_URL = `${API_URL}/Customers/GetLookUpList`
export const GET_All_MENUITEMS_URL = `${API_URL}/MenuItems/GetLookUpList`
export const GET_All_GROUPS_URL = `${API_URL}/Groups/getall`
export const USER_URL = `${API_URL}/Users`
export const DELETE_USER_URL = `${API_URL}/Users?deleteUserId=`
export const UPDATE_USER_MENU_ITEM_RIGHTS_BY_USER_ID_URL = `${API_URL}/UserMenuItemRights/UpdateGroupMenuItemRightsByUserId`
export const UPDATE_USER_GROUPS_BY_USER_ID_URL = `${API_URL}/UserGroups/UpdateUserGroupsByUserId`

export function UpdateUserMenuItemRights(model: UpdateUserMenuItemRightModel) {
  return axios.put(UPDATE_USER_MENU_ITEM_RIGHTS_BY_USER_ID_URL, model)
}
export function UpdateUserGroups(model: UpdateUserGroupsModel) {
  return axios.put(UPDATE_USER_GROUPS_BY_USER_ID_URL, model)
}
export function GetAllUsers() {
  return axios.get<ListUserModel[]>(GET_ALL_USERS_URL)
}
export function GetAllMenuItems() {
  return axios.get<MenuItemModel[]>(GET_All_MENUITEMS_URL)
}
export function GetAllGroups() {
  return axios.get<GroupModel[]>(GET_All_GROUPS_URL)
}
export function CreateUser(user: CreateUserModel) {
  return axios.post(USER_URL, user)
}
export function UpdateUser(user: UpdateUserModel) {
   
  return axios.put(USER_URL, user)
}
export function DeleteUser(userId: number) {
  return axios.delete(DELETE_USER_URL + userId)
}
export function GetUserTypes() {
  return axios.get<UserTypeModel[]>(GET_ALL_USER_TYPES_URL)
}
export function GetCustomers() {
  return axios.get<CustomerModel[]>(GET_ALL_CUSTOMERS_URL)
}