import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/Auth/GetUserByAccessToken`
export const LOGIN_URL = `${API_URL}/Auth/login`
export const REGISTER_URL = `${API_URL}/Auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/ForgotPassword`
export const RESET_PASSWORD_URL = `${API_URL}/auth/ResetPassword`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {email: email, password: password})
}
// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}
export function ResetUserPassword(
  password: string,
  passwordAgain: string,
  isUserCheck: boolean,
  passwordResetKey: string
) {
  return axios.post<{result: boolean}>(RESET_PASSWORD_URL, {
    password,
    passwordAgain,
    isUserCheck,
    passwordResetKey,
  })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
