import axios from 'axios'
import { SelectItem } from '../../../api-models/SelectItem'


const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_All_COLLECTION_NAMES_URL = `${API_URL}/Mongo/GetAllCollectionNames`
export const GET_COLLECTION_BY_NAME_URL = `${API_URL}/Mongo/GetCollectionByName`
export const GET_GRAPHICREPORTBUILDERDATA_BYCOLLECTIONNAME_URL = `${API_URL}/Mongo/GetGraphicReportBuilderDataByCollectionName`

export const SAVE_REPORT_URL = `${API_URL}/ReportDefs`


export function GetAllCollectionNames() {
  return axios.get<string[]>(GET_All_COLLECTION_NAMES_URL)
}
export function GetCollectionByName(collectionName:string) {
  return axios.post<string>(GET_COLLECTION_BY_NAME_URL,{collectionName:collectionName})
}
export function GetReportBuilderDataByCollectionName(collectionName:string) {
  return axios.post<DataCubeData>(GET_GRAPHICREPORTBUILDERDATA_BYCOLLECTIONNAME_URL,{collectionName:collectionName})
}
export function SaveReport(reportData:ICreateReport) {
  return axios.post(SAVE_REPORT_URL,reportData)
}

export interface DataCubeData {
  dataCubeName:string
  dataCubeColumns:SelectItem[]
  dataCubeData:string
}

export interface ICreateReport {
  reportTypeId:number,
  reportName: string
  dataCubeName: string
  graphicTypeId: number
  graphicOptionJson: string
  reportSqlText:string
}